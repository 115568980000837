import TimesOneMobiledataIcon from '@mui/icons-material/TimesOneMobiledata';
import InstanceMultiplierModal from "./InstanceMultiplierModal";
import { CreateInstanceDialog } from "./CreateInstanceDialog";
import { SectionHeading } from "helpers/SectionHeading";
import InstanceDeleteModal from "./InstanceDeleteModal";
import { formatFloat } from 'helpers/DigitFormatter';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState, useEffect, useRef } from "react";
import CachedIcon from '@mui/icons-material/Cached';
import { useUrlParams } from "utils/useUrlParams";
import RadarIcon from '@mui/icons-material/Radar';
import green from '@mui/material/colors/green';
// import Edit from "@mui/icons-material/Edit";
import { Socket } from 'Root/Common/Socket';
import { mToast } from "Root/Common/Toast";
import red from '@mui/material/colors/red';
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "helpers/Table";
import Box from "@mui/material/Box";
import { root } from "Root/root";

const columns = [
    { id: "tag", label: "Tag", minWidth: 10, align: "left"},
    { id: "users", label: "Users", minWidth: 100, align: "center" },
    { id: "index", label: "Index", minWidth: 100, align: "center" },
    // { id: "entry_time", label: "Entry Time", minWidth: 100, align: "center" },
    // { id: "exit_time", label: "Exit Time", minWidth: 100, align: "center" },
    { id: "lots", label: "Lots", minWidth: 100, align: "center" },
    // { id: "call_type", label: "Call Type", minWidth: 100, align: "center" },
    { id: "net_qty", label: "Net Quantity", minWidth: 100, align: "center", format(value) {return <Box 
        width="100%" display="flex" justifyContent="center" color={value > 0 ? green[600] : red[600]}>
            <Box width={70}>{formatFloat(value)}</Box>
        </Box>
    } },
    { id: "net_mtm", label: "Net MTM", minWidth: 100, align: "center", format(value) {return <Box 
        width="100%" display="flex" justifyContent="center" color={value > 0 ? green[600] : red[600]}>
            <Box width={70}>{formatFloat(value)}</Box>
        </Box>
    } },
    { id: 'inst_action', label: "Action", minWidth: 10, align: 'right' }
]

export function StrategyInstanceHome() {
    const [, updateParams] = useUrlParams();
    const [isInstanceModalOpen, setIsInstanceModalOpen] = useState(false)
    
    const [isInstDelModalOpen, setIsInstDelModalOpen] = useState(false)
    const [isInstMulpModalOpen, setIsInstMulpModalOpen] = useState(false)
    const [instNetQtyMap, setInstNetQtyMap] = useState({})

    const [selInstDelId, setSelInstDelId] = useState(null)
    const [selInstDelTag, setSelInstDelTag] = useState(null)
    const [selInstMulpId, setSelInstMulpId] = useState(null)
    const [selInstMulpTag, setSelInstMulpTag] = useState(null)

    const commonSel = useSelector(selector => selector.common)
    const [instances, setInstances] = useState({
        list: []
    })

    const socketFunHandler = useRef({
        allInstPos() {}
    })

    const allInstPosPingPong = async (socket) => {        
        while (socket.socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({"wbType": "all-inst", "loginId": commonSel.loginId}))
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }

    const allInstPosSocket = () => {
        const connData = {"wbType": "all-inst", "loginId": commonSel.loginId}
        const connQuery = new URLSearchParams(connData);

        var socket = new Socket(root.constant.WEBSOCKET_URL + "broadcast/stg_instance/?" + connQuery);

        socket.onOpen(async function () {
            socket.send(JSON.stringify({"wbType": "all-inst", "loginId": commonSel.loginId}))
            await allInstPosPingPong(socket);
        })
        
        socket.onMessage(function (data) {
            let posData = data.posData;

            if (posData) {
                for (let posKey in posData) {
                    if (typeof socketFunHandler.current[posKey] === "function") {
                        socketFunHandler.current[posKey] (posData[posKey])
                    }
                }
            }
        })

        var isAllInstPosSocketClose = false;
    
        socket.onClose(function(event) {
            // Attempt to reconnect to the websocket after a delay
            if (!isAllInstPosSocketClose) {
                setTimeout(() => {
                    allInstPosSocket()
                }, 1000);
            }
        })

        return () => {
            isAllInstPosSocketClose = true
            socket.close()
        }
    }

    useEffect(() => {
        return allInstPosSocket()
    }, [])

    useEffect(() => {
        const createData = (inst = {}) => {
            const cfgId = inst.cfg_id

            return {
                cfg_id: cfgId,
                tag: inst.tag,
                users: inst.users,
                index: inst.index,
                // entry_time: inst.entry_time,
                // exit_time: inst.exit_time,
                lots: inst.lots,
                // call_type: inst.call_type,
                net_qty: inst.net_qty,
                net_mtm: inst.net_mtm,
                inst_action: (
                    <Box display="flex" justifyContent="end">
                        {/* <Link to={"/strategyInstance?type=edit&cfgId=" + cfgId + "&tag=" + inst.tag}>
                            <Button
                                startIcon={<Edit style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                            >
                                Edit
                            </Button>
                        </Link> */}
                        
                        <Link>
                            <Button
                                startIcon={<TimesOneMobiledataIcon fontSize="large" />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                                onClick={() => {
                                    setIsInstMulpModalOpen(true)
                                    setSelInstMulpId(cfgId)
                                    setSelInstMulpTag(inst.tag)
                                }}
                            >
                                Multiplier
                            </Button>
                        </Link>

                        <Link to={"/strategyInstance?type=position&cfgId=" + cfgId + "&tag=" + inst.tag}>
                            <Button
                                startIcon={<RadarIcon style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                            >
                                Positions
                            </Button>
                        </Link>

                        <Link>
                            <Button
                                startIcon={<DeleteIcon style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                                color="error"
                                onClick={() => {
                                    setIsInstDelModalOpen(true)
                                    setSelInstDelId(cfgId)
                                    setSelInstDelTag(inst.tag)
                                }}
                            >
                                Delete
                            </Button>
                        </Link>
                    </Box>
                )
            };
        }

        root.apis.strategyInstance.getInstances(commonSel.loginId)
        .then(data => {
            const list = (data.data.instances || []).map(createData)

            setInstances(prev => ({
                ...prev,
                list
            }))
        })
        .catch(err => {
            console.log("Get Instances Error: ", err)
            mToast.error(err.statusText)
        })
    }, [])

    socketFunHandler.current.allInstPos = (data) => {
        if (instances) {
            instances.list.forEach(inst => {
                const filterData = data.find(elem => inst.cfg_id === elem.cfgId)

                if (filterData) {
                    inst.net_mtm = filterData.netMtm
                    inst.net_qty = filterData.netQty

                    setInstNetQtyMap(prev => ({
                        ...prev,
                        [inst.cfg_id]: filterData.netQty
                    }));
                }
            })

            setInstances(prev => ({
                ...prev,
                instances
            }))
        }
    }

    console.log("instNetQtyMap", instNetQtyMap)

    return (
        <Box>
            <SectionHeading
                heading={"Strategy Instance"}
            >
                <Box
                    display="flex" gap={3} justifyContent="end" alignItems="center" justifySelf="end"
                >
                    <Button 
                        sx={{ minWidth: 20, p: 0 }} 
                        color="primary" 
                        onClick={() => {
                            window.location.reload();
                        }} 
                    > <CachedIcon sx={{ fontSize: 30 }} /> </Button>

                    <Button
                        variant="contained"
                        style={{ textTransform: 'initial' }}
                        onClick={() => {setIsInstanceModalOpen(true)}}
                    >
                        Create Instance
                    </Button>
                </Box>
            </SectionHeading>

            <Box mt={2}>
                <Table
                    columns={columns}
                    rows={instances.list}
                />
            </Box>

            <CreateInstanceDialog
                open={isInstanceModalOpen}
                onClose={() => setIsInstanceModalOpen(false)}
                setIsInstanceModalOpen={setIsInstanceModalOpen}
                commonSel={commonSel}
            />

            <InstanceDeleteModal 
                open={isInstDelModalOpen}
                handleClose={() => setIsInstDelModalOpen(false)}
                instCfgId={selInstDelId}
                instTag={selInstDelTag}
            />

            {
                isInstMulpModalOpen
                ? <InstanceMultiplierModal 
                    open={isInstMulpModalOpen}
                    handleClose={() => setIsInstMulpModalOpen(false)}
                    instCfgId={selInstMulpId}
                    instTag={selInstMulpTag}
                    netQty={instNetQtyMap[selInstMulpId]}
                />
                : ""
            }
        </Box>
    )

}