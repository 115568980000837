import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { SectionHeading } from "helpers/SectionHeading";
import { formatFloat } from 'helpers/DigitFormatter';
import { useUrlParams } from 'utils/useUrlParams';
import green from '@mui/material/colors/green';
import { useEffect, useState } from 'react';
import { Socket } from 'Root/Common/Socket';
import red from '@mui/material/colors/red';
import { mToast } from 'Root/Common/Toast';
import { IconButton } from '@mui/material';
import Button from "@mui/material/Button";
import { useSelector } from 'react-redux';
import { Table } from "helpers/Table";
import Box from "@mui/material/Box";
import { root } from 'Root/root';

const columns = [
    { id: "userId", label: "User ID", align: "left", minWidth: 50, format(value, row) {
        return <Box
            display="flex"
            alignItems="center"
            gap={0.75}
        >
            <IconButton sx={{
                paddingLeft: 0
            }}>
                <PowerSettingsNewIcon
                    color={row.isLive === true ? "success" : "error"}
                    onClick={() => {
                        root.apis.users.forceLogin(value)
                        .then((data) => {
                            const respObj = data.data

                            if (respObj.msg) {
                                mToast.success(respObj.msg)
                            }
                        })
                        .catch((err) => {
                            const err_resp = err.response
                
                            if (err_resp.status === 403) {
                                mToast.error(err_resp.data.err)
                            }
                            else {
                                mToast.error("Couldn't complete request. Something went wrong!")
                            }
                        })
                    }}
                />
            </IconButton>

            <Box>{value}</Box>
        </Box>
    } },
    { id: "fullName", label: "Full Name" },
    { id: "usedMargin", label: "Used Margin", align: "right", format(value) {
        return <Box color="grey">{value ? formatFloat(value) : ""}</Box>
    } },
    { id: "netMargin", label: "Net Margin", align: "right", format(value) {
        return <Box color="grey">{value ? formatFloat(value) : ""}</Box>
    } },
    { id: "brokerMtm", label: "Broker MTM", align: "right", format(value) {return <Box 
        width="100%" display="flex" justifyContent="right" color={value > 0 ? green[600] : value < 0 ? red[600] : "grey"}>
            <Box width={70}>{value ? formatFloat(value) : 0}</Box>
        </Box>
    } },
    // { id: "brokerUnRlMtm", label: "Unrealized MTM", align: "center", format(value) {
    //     return value ? formatFloat(value) : 0
    // } },
    // { id: "brokerRlMtm", label: "Realized MTM", align: "center", format(value) {
    //     return value ? formatFloat(value) : 0
    // } },
    { id: "mtm", label: "Propdesk MTM", align: "right", format(value) {return <Box 
        width="100%" display="flex" justifyContent="right" color={value > 0 ? green[600] : value < 0 ? red[600] : "grey"}>
            <Box width={70}>{formatFloat(value)}</Box>
        </Box>
    }},
    { id: "view", label: "", align: "center", minWidth: 125 },
]

// posObj= {
//     "id": 21,
//     "userId": 18,
//     "fullName": "Olivia Wilson",
//     "mtm": 148283
//   },

export function UsersList() {
    const [usersList, setUsersList] = useState([])
    const [,updateParams] = useUrlParams()

    const commonSel = useSelector(s => s.common)
    
    useEffect(() => {
        const createTableRows = (pos={}) => ({ 
            userId: pos.userId, 
            fullName: pos.fullName, 
            mtm: pos.mtm,
            brokerUserId: pos.brokerUserId,
            view: (
                <Button
                    startIcon={<RemoveRedEyeIcon />}
                    variant="text"
                    style={{
                        textTransform: "initial"
                    }}
                    onClick={() => updateParams({ type: "view", userId: pos.userId, fullName: pos.fullName, brokerUserId: pos.brokerUserId })}
                >
                    View
                </Button>
            )
        })

        if (commonSel.loginId) {
            root.apis.positions.get(commonSel.loginId)
            .then(d=>{
                setUsersList((d.data?.pos || []).map(createTableRows))
            })
            .catch(e=>{
                mToast.error(e.message)
            })
        }
    }, [commonSel.loginId])

    const posAllClientsPingPong = async (socket) => {        
        while (socket.socket.readyState === WebSocket.OPEN) {
            console.log("Sending posAllClientsPingPong request")

            socket.send(JSON.stringify({"wbType": "all-clients"}))
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }

    const posAllClientsSocket = () => {
        const connData = {"wbType": "all-clients"}
        const connQuery = new URLSearchParams(connData);

        var socket = new Socket(root.constant.WEBSOCKET_URL + "broadcast/position/?" + connQuery);
        
        socket.onOpen(async function () {
            console.log("Connecting to positions (all-clients) broadcast websocket")

            socket.send(JSON.stringify({"wbType": "all-clients"}))
            await posAllClientsPingPong(socket);
        })
        
        socket.onMessage(function (data) {
            let posData = data.posData;
            let msg = data.msg;

            if (msg) {
                console.log(msg)
            }

            if (posData) {
                setUsersList(prev => {
                    posData.forEach(elem => {
                        const filterData = prev.find(e => e.userId === elem.userId)
                        
                        if (filterData) {
                            filterData.mtm = elem.mtm
                            filterData.usedMargin = elem.usedMargin
                            filterData.netMargin = elem.netMargin
                            filterData.brokerMtm = elem.brokerMtm
                            filterData.brokerUnRlMtm = elem.brokerUnRlMtm
                            filterData.brokerRlMtm = elem.brokerRlMtm
                            filterData.isLive = elem.isLive
                        }
                    })
    
    
                    return [...prev]
                })
            }
        })

        var isPosAllClSocketClose = false;
    
        socket.onClose(function(event) {
            console.log("Positions (all-clients) broadcast websocket connection closed", event)

            // Attempt to reconnect to the websocket after a delay
            if (!isPosAllClSocketClose) {
                setTimeout(() => {
                    console.log(`Reconnecting to position (all-clients) webSocket ...`);

                    posAllClientsSocket()
                }, 1000);
            }
        })

        return () => {
            isPosAllClSocketClose = true
            socket.close()
        }
    }

    useEffect(() => {
        return posAllClientsSocket()
    }, [])

    return (
        <Box>
            <SectionHeading
                heading={"P&L Dashboard"}
            />
            <Box mt={3}>
                <Table
                    columns={columns}
                    rows={usersList}
                />
            </Box>
        </Box>
    )
}