
const Users = (self) => ({

    get(userId){
        // if user id not provided, then it will fetch all users data
        return self.axios.get(self.getRestUrl(["user" + (userId ? "" : "s"), userId].filter(e=>e)))
    },
    refreshMargin(payload={}){
        return self.axios.post(self.getRestUrl(["user", "refresh_margin"]), payload)
    },
    register(payload={}){
        return self.axios.post(self.getRestUrl(["user", "register"]), payload)
    },
    edit(userId, payload={}) {
        return self.axios.post(self.getRestUrl(["user", "edit", userId]), payload)
    },
    delete(userId){
        return self.axios.delete(self.getRestUrl(["user", "delete", userId]))
    },
    forceLogin(userId) {
        return self.axios.post(self.getRestUrl(["user", "force_login", userId]))
    }
    // login(payload={}, headers={}){
    //     return self.axios.post(self.getRestUrl(["user", "login"]), payload, { headers: headers })
    // },
    // logout(userId){
    //     return self.axios.post(self.getRestUrl(["user", "logout", userId]))
    // },
    // validateToken(payload={}) {
    //     return self.axios.post(self.getRestUrl(["user", "validate_token"]), payload)
    // }

})

export default Users