import DeleteForever from "@mui/icons-material/DeleteForever";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Container, Divider } from "@mui/material";
import EnableDisableSwitchElem from "helpers/Switches";
import Typography from "@mui/material/Typography";
import { useUrlParams } from "utils/useUrlParams";
import ClearIcon from '@mui/icons-material/Clear';
import TextField from "@mui/material/TextField";
import FormGroup from '@mui/material/FormGroup';
import { useEffect, useState } from "react";
import Save from "@mui/icons-material/Save";
import { mToast } from "Root/Common/Toast";
import { DropDown } from "helpers/DropDown";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { root } from "Root/root";
import { toast } from "react-toastify";

function useInputsSchema(searchParams, setSearchParams) {
    const [schema, setSchema] = useState([
        {
            heading: "Strategy Information",
            key: "stgInfo",
            inputs: [
                { label: "Strategy Code", key: "code" },
                { label: "Strategy Name", key: "name" },
                { label: "Strategy Description", key: "desc" },
                { label: "File Name", key: "fileName" },
                { label: "File Path", key: "filePath" },
                { label: "Required Margin (Per lot)", key: "marginReq" },
                { label: "Max. Lots", key: "maxLots" },
            ]
        },
        {
            heading: "Settings",
            key: "stgSettings",
            inputs: [
                { label: "Autostart", key: "isEnable", type: "checkbox", value: true },
                { label: "Is Positional ?", key: "isPos", type: "checkbox", value: false }
            ]
        }
    ]);
    const [apiData, setApiData] = useState()
    
    const [usersList, setUsersList] = useState([])
    const [startStopUser, setStartStopUser] = useState(null)
    const [stgParams, setStgParams] = useState();

    // Fetch user details
    const createSelUser = (d) => {
        var isSelected = false
        const loginId = d.loginId

        if (apiData && apiData.startStopUser === loginId) {
            isSelected = true
            setStartStopUser(loginId)
        }

        return { loginId: loginId, value: d.fullName, selected: isSelected, label: d.fullName }
    }

    useEffect(() => {
        root.apis.loginUsers.get()
        .then((d) => {
            setUsersList(d.data.lUsers.map(d => createSelUser(d)))
        })
        .catch(err => { mToast.error(err.message) })
    }, [apiData])

    const onChangeInputs = (e, schemaIndex, inputIndex) => {
        const schemaObj = schema[schemaIndex].inputs[inputIndex]
        
        if (schemaObj.type === "checkbox") {
            schemaObj.value = e.target.checked
        }
        else {
            schemaObj.value = e.target.value
        }

        setSchema([...schema])
    }

    const onSumbit = () => {
        const payload = {}

        for (let section of schema) {
            for (let input of section.inputs) {
                if ("key" in input) {

                    if (input.type === "checkbox") {
                        payload[input.key] = input.value
                    }
                    else {
                        payload[input.key] = input.value
                    }
                }
            }
        }

        payload.startStopUser = startStopUser

        try {
            payload.params = JSON.parse(stgParams);
            
        } catch (err) {
            toast.error("Strategy parameters are in invalid format!")
            return;
        }

        const isEdit = searchParams.get("type") === "edit"

        root.apis.strategy[isEdit ? "edit" : "create"](...[isEdit && apiData.stgId, payload].filter(e => e))
        .then((data) => {
            mToast.success(data.data.msg)
            setSearchParams()
        })
        .catch(e => {
            mToast.error(e.statusText)
        })
    }

    useEffect(() => {
        if (searchParams.get("type") === "edit") {
            const stgId = searchParams.get("stgid")

            if (stgId) {
                root.apis.strategy.get(stgId)
                    .then(d => {
                        const stgEditData = d.data

                        const assignDataToSchema = (prevSchema) => {
                            if (stgEditData) {
                                prevSchema.forEach(section => {
                                    section.inputs.forEach(input => {
                                        const value = stgEditData[input.key]
    
                                        if (value || "boolean" === typeof value || value === 0) {
                                            input.value = value
                                        }
                                    })
                                })
                            }

                            return [...prevSchema]
                        }

                        if ("err" in d.data) {
                            mToast.error(d.data.err)

                        } else {
                            setSchema(assignDataToSchema)
                            setApiData(d.data)
                            setStgParams(JSON.stringify(d.data?.params || {}, null, 2))
                        }

                    })
                    .catch(e => {
                        console.log("Get Strategy By ID Error: ", stgId, e)
                        mToast.error(e.statusText)
                    })
            } else {
                mToast.info("stgId is required to edit the strategy.")
            }
        }
    }, [searchParams.get("type")])

    return [schema, onChangeInputs, onSumbit, apiData, usersList, setUsersList, setStartStopUser, stgParams, setStgParams]
}

function SaveButtonSection({ setSearchParams, onSumbit, isStgEditable }) {

    return (
        <Box display={"flex"} justifyContent={"end"} my={2}>
            <Box>
                <Box display={'flex'} justifyContent={'end'} gap={5} mt={2}>
                    <Button
                        variant="outlined"
                        style={{ textTransform: 'initial', color: '#707070' }}
                        onClick={() => {
                            setSearchParams()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<Save />}
                        onClick={onSumbit}
                        disabled={!isStgEditable}
                        sx={{
                            color: "white !important",
                            backgroundColor: "#2E7D32 !important",
                            opacity: isStgEditable ? 1 : 0.5
                        }}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Box>

    )

}

function DeleteAccountSection({ apiData, searchParams, setSearchParams }) {
    const [isDeleteCall, setIsDeleteCall] = useState(false)

    if (!apiData || searchParams.get("type") !== "edit" || !apiData.stgId) return null;

    const onDelete = () => {

        if (apiData && apiData.stgId) {
            if (!window.confirm("Are you sure you want to delete this strategy?")) return;
            setIsDeleteCall(true)

            root.apis.strategy.delete(apiData.stgId)
                .then(d => {
                    mToast.success(d.data.msg)
                    setSearchParams()
                })
                .catch(e => {
                    mToast.error(e.statusText)
                })
                .finally(() => {
                    setIsDeleteCall(false)
                })
        }

    }

    return (
        <Box mt={2}>
            <Box mb={1}>
                <Typography>
                    Delete Strategy
                </Typography>
            </Box>
            <Button
                startIcon={<DeleteForever />}
                variant="outlined"
                color="error"
                style={{ textTransform: 'initial' }}
                onClick={onDelete}
            >
                {isDeleteCall ? "Delete your strategy..." : "Remove Strategy"}
            </Button>
        </Box>
    )
}

export function StrategyAction() {
    const [searchParams, , setSearchParams] = useUrlParams()
    const [schema, onChangeInputs, onSumbit, apiData, usersList, setUsersList, setStartStopUser, stgParams, setStgParams] = useInputsSchema(searchParams, setSearchParams)

    const isEdit = searchParams.get("type") === "edit"
    const isStgEditable = isEdit ? apiData?.isEditable || false : true

    return (
        <Container disableGutters style={{ position: 'relative' }}  >
            <Box
                sx={{
                    width: '100%',
                }}
                className="custom-inputs"
                noValidate
                autoComplete="off"
            >

                {
                    schema.map((schema = {}, schemaIndex) => {
                        
                        return (
                            <Box
                                key={schema.heading}
                                sx={{
                                    mt: schemaIndex ? 3 : 0
                                }}
                            >
                                <Box mb={2}>
                                    <Typography style={{
                                        fontSize: 18,
                                        textDecoration: 'underline',
                                        textUnderlineOffset: 5,
                                        color: "#5497db"
                                    }}>
                                        {schema.heading}
                                    </Typography>
                                </Box>   
                                
                                <Grid 
                                    container 
                                    spacing={{ xs: 2, md: 4 }}
                                >
                                    {
                                        schema.inputs.map((input = {}, inputIndex) => {
                                            const isCheckBox = input.type === "checkbox"

                                            return (
                                                <Grid
                                                    key={input.label}
                                                    item
                                                    xs={12}
                                                    sm={isCheckBox ? 6 : 6}
                                                    md={isCheckBox ? 3 : 4}
                                                >

                                                    {
                                                        isCheckBox ? 
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <EnableDisableSwitchElem/>
                                                                }
                                                                
                                                                label={input.label}
                                                                checked={input.value}
                                                                disabled={!isStgEditable}
                                                                onChange={(e) => { onChangeInputs(e, schemaIndex, inputIndex) }}
                                                            />
                                                        </FormGroup>
                                                        :
                                                        <TextField
                                                            fullWidth
                                                            onChange={(e) => { onChangeInputs(e, schemaIndex, inputIndex) }}
                                                            value={input.value ?? ""}
                                                            variant="standard"
                                                            disabled={!isStgEditable}
                                                            {...input}
                                                        />
                                                    }

                                                </Grid>
                                            )
                                            
                                        })
                                    }
                                </Grid>

                            </Box>
                        )
                    })
                }

                <Box
                    key="Permissions"
                    sx={{
                        mt: 3
                    }}
                >
                    <Box sx={{ mb: 3 }}>
                        <Typography style={{
                            fontSize: 18,
                            textDecoration: 'underline',
                            textUnderlineOffset: 5,
                            color: "#5497db"
                        }}>
                            User With Start/Stop Permissions
                        </Typography>
                    </Box>

                    <Grid container spacing={{ xs: 2, md: 4 }}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                        >
                            <DropDown
                                defaultValue="Select Users"
                                searchPlaceholder="Search Users..."
                                value={usersList.filter(obj => obj.selected)}
                                options={usersList}
                                onSelect={(user, isSelected, isArray)=> {
                                    usersList.forEach((user) => {
                                        user.selected = false
                                    })

                                    if (isSelected) {
                                        setStartStopUser(user.loginId)
                                    }
                                    else {
                                        setStartStopUser(null)
                                    }

                                    user.selected = isSelected
                                    setUsersList([...usersList])
                                }}
                            >
                                <TextField
                                    fullWidth
                                />
                            </DropDown>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            style={{
                                alignContent: "center"
                            }}
                        >
                            <ClearIcon
                                color="error"
                                fontSize="large"
                                sx={{
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    usersList.forEach((user) => {
                                        user.selected = false
                                    })

                                    setUsersList([...usersList])
                                    setStartStopUser(null)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    key="strategyParams"
                    sx={{
                        mt: 3
                    }}
                >
                    <Box sx={{ mb: 3 }}>
                        <Typography style={{
                            fontSize: 18,
                            textDecoration: 'underline',
                            textUnderlineOffset: 5,
                            color: "#5497db"
                        }}>
                            Strategy Parameters
                        </Typography>
                    </Box>

                    <Grid container spacing={{ xs: 2, md: 6 }}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                        >
                            <TextField
                                fullWidth
                                multiline
                                rows={25}
                                onChange={(e) => {
                                    setStgParams(e.target.value);
                                }}
                                sx={{ 
                                    "& .MuiInputBase-input": { 
                                        color: "black"
                                    }
                                }}
                                value={stgParams}
                                disabled={!isStgEditable}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <SaveButtonSection
                    setSearchParams={setSearchParams}
                    onSumbit={onSumbit}
                    isStgEditable={isStgEditable}
                />

                <Divider color="gray" />

                <DeleteAccountSection
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    apiData={apiData}
                />

            </Box>
        </Container>
    )

}