import { SectionHeading } from "helpers/SectionHeading";
import { Box, Button, Divider } from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import { useUrlParams } from "utils/useUrlParams";
import { NetPosition } from "./NetPosition";
import { Socket } from 'Root/Common/Socket';
import { mToast } from "Root/Common/Toast";
import { useEffect, useRef } from "react";
import { TradeBook } from "./TradeBook";
import { root } from "Root/root";

export function Position() {
    const [searchParams, updateParams, removeParams] = useUrlParams()
    const socketFunHandler = useRef({
        posData() {},
        totalMTM() {}
    })

    const cfgId = searchParams.get("cfgId")

    const netPosPingPong = async (socket) => {        
        while (socket.socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({"wbType": "single-inst", "cfgId": cfgId}))
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }

    const netPosSocket = () => {
        const connData = {"wbType": "single-inst", "cfgId": cfgId}
        const connQuery = new URLSearchParams(connData);

        var socket = new Socket(root.constant.WEBSOCKET_URL + "broadcast/stg_instance/?" + connQuery);

        socket.onOpen(async function () {
            socket.send(JSON.stringify({"wbType": "single-inst", "cfgId": cfgId}))
            await netPosPingPong(socket);
        })
        
        socket.onMessage(function (data) {
            let posData = data.posData;

            if (posData) {
                for (let posKey in posData) {
                    if (typeof socketFunHandler.current[posKey] === "function") {
                        socketFunHandler.current[posKey] (posData[posKey])
                    }
                }
            }
        })

        var isPosSocketClose = false;
    
        socket.onClose(function(event) {
            // Attempt to reconnect to the websocket after a delay
            if (!isPosSocketClose) {
                setTimeout(() => {
                    netPosSocket()
                }, 1000);
            }
        })

        return () => {
            isPosSocketClose = true
            socket.close()
        }
    }

    const handleSquareOff = () => {
        root.apis.strategyInstance.squareOff(cfgId)
        .then((data) => {
            const resp = data.data

            if (resp.msg) {
                mToast.success(resp.msg)
            }
        })
        .catch((err) => {
            const err_resp = err.response

            if (err_resp) {
                if (err_resp.status === 403) {
                    mToast.error(err_resp.data.err)
                }
                else {
                    mToast.error("Couldn't complete request. Something went wrong!")
                }
            }
            else {
                mToast.error("Couldn't complete request. Something went wrong!")
            }
        })
    }

    useEffect(() => {
        var netPos = netPosSocket()

        return () => {
            netPos()
        };
    }, [])

    return (
        <Box>
            <SectionHeading
                heading={`Instance: ${searchParams.get("tag")}`}
            >
                <Box>
                    {/* Reload Button */}
                    <Button 
                        sx={{ minWidth: 20, p: 0 }} 
                        color="primary" 
                        onClick={() => {
                            window.location.reload();
                        }} 
                    > <CachedIcon sx={{ fontSize: 30 }} /> </Button>

                    {/* Back Button */}
                    <Button 
                        variant="outlined"
                        style={{ textTransform: "initial" }}
                        color="inherit"
                        onClick={() => removeParams()}
                        sx={{ ml: 3 }} 
                    >
                        Back
                    </Button>

                    <Button
                        variant="contained"
                        style={{ textTransform: "initial" }}
                        sx={{ ml: 3 }} 
                        onClick={handleSquareOff}
                    >
                        Square Off
                    </Button>
                </Box>
            </SectionHeading>

            <Box mt={1}>
                <Divider color="gray" />
            </Box>

            <Box mt={2}>
                <NetPosition
                    socketFunHandler={socketFunHandler}
                />
                <TradeBook
                    cfgId={cfgId}
                    socketFunHandler={socketFunHandler}
                />
            </Box>
        </Box>
    )
}