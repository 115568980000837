import { default as MuiTableCell} from '@mui/material/TableCell';
import { Button, Typography, Divider } from "@mui/material";
import TableContainer from '@mui/material/TableContainer';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useEffect, useState, Fragment } from "react";
import { green, red } from "@mui/material/colors";
import { formatePrice } from "utils/formatPrice";
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { root } from "../../../../Root/root";
import MuiTable from '@mui/material/Table';
import { mToast } from "Root/Common/Toast";
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Box from "@mui/material/Box";
import Tab from '@mui/material/Tab';

const handleSymbolExit = (data) => {
    var userId = data.userId
    var stgId = data.stgId

    if (userId) {
        userId = parseInt(userId)
    }

    if (stgId) {
        stgId = parseInt(stgId)
    }
    else {
        stgId = null
    }

    root.apis.orderConfig
    .symbolExit({ userId: userId, brokerUserId: data.brokerUserId, excInstId: data.excInstId, stgId: stgId, propTag: `symbol-exit-${data.loginId}` })
    .then(d=>{
        if (d.data.msg) {
            mToast.success(d.data.msg)
        }

        if (d.data.err) {
            mToast.error(d.data.err)
        }
    })
    .catch(err => {
        const err_resp = err.response

        if (err_resp.status === 403) {
            mToast.error(err_resp.data.err)
        }
        else {
            mToast.error(err.message)
        }
    })
}

export const netPositionColumns = [
    { id: "symbol", label: "Symbol", format(value, rowData={}) {
        return <Box 
            display="flex" justifyContent="left"
        >
            {value}
            {
                rowData.isXtsCol === false ?
                <Box ml={1.5}>
                    <ExitToAppIcon
                        fontSize="small"
                        cursor="pointer"
                        onClick={() => {handleSymbolExit(rowData)}}
                    />
                </Box>
                : ""
            }
        </Box>
    } },
    { id: "ltp", label: "LTP", align: "center", format(value) { return <Box 
        width="100%" display="flex" justifyContent="center">
        <Box width={70}>{value}</Box>
    </Box> } },
    { id: "mtm", label: "MTM", align: "center", format(value, rowData = {}) { return <Box 
        width="100%" display="flex" justifyContent="center">
        <Typography width={70} color={rowData.mtm > 0 ? green[600] : red[600]}>{value}</Typography>
    </Box> } },
    { id: "netQty", label: "Net Qty", align: "center", format(value, rowData = {}) { return <Typography color={rowData.netQty > 0 ? green[600] : red[600]}>{value}</Typography> } },
    { id: "buyQty", label: "Buy Qty", align: "center" },
    { id: "avgBuyPrice", label: "Avg. Buy", align: "center", format(value) { return formatePrice(value, 2) } },
    { id: "sellQty", label: "Qty Sell", align: "center" },
    { id: "avgSellPrice", label: "Avg. Sell", align: "center", format(value) { return formatePrice(value, 2) } },
    { id: "buyVal", label: "Buy Val.", align: "right", format(value) { return formatePrice(value, 2) } },
    { id: "sellVal", label: "Sell Val.", align: "right", format(value) { return formatePrice(value, 2) } }
]

function TableCell({ children, ...attr }){
    if (!attr.sx) attr.sx={}
    attr.sx = { p: 0, ...attr.sx }

    return (
        <MuiTableCell {...attr}>
            { children }
        </MuiTableCell>
    )
}

export function NetPositionWithXTS({ 
    netRowData = {}, xtsRowData = {}, setActivePosTab = null
}) {
    const [activeTab, setActiveTab] = useState(false);
    const [isNetPosHidden, setIsNetPosHidden] = useState(true)
    const [isXTSPosHidden, setIsXTSPosHidden] = useState(false)
    const [rowData, setRowData] = useState({
        beforeNetPLRowData: [],
        total: { mtm: 0, netQty: 0, buyQty: 0, avgBuyPrice: 0, sellQty: 0, avgSellPrice: 0, buyVal: 0, sellVal: 0 },
        afterNetPLRowData: [],
        isAfterNetPLRowDataVisible: false
    });

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue)
        setActivePosTab(newValue)

        if (newValue === "1") {
            if (isNetPosHidden !== true) {
                setIsNetPosHidden(!isNetPosHidden)
            }
            setIsXTSPosHidden(false)
        }
        
        if (newValue === "2") {
            setIsXTSPosHidden(!isXTSPosHidden)
            setIsNetPosHidden(false)
        }
    }

    useEffect(() => {
        setActiveTab("1")
        setActivePosTab("1")
    }, [])

    useEffect(() => {
        if (isNetPosHidden) {
            setRowData(netRowData)
        }
    
        if (isXTSPosHidden) {
            setRowData(xtsRowData)
        }
    }, [netRowData, xtsRowData, isNetPosHidden, isXTSPosHidden])

    return (
        <Box>
            <Box sx={{ width: activeTab ? '90%' : '100%' }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                >
                    <Tab value="1" label={"Net Position"} style={{ color: "White", fontSize: 18, padding: 0, textTransform: "capitalize" }} />
                    <Tab value="2" label={"XTS Position"} style={{ color: "White", fontSize: 18, padding: 0, textTransform: "capitalize", marginLeft: 20 }} />
                </Tabs>
            </Box>

            <Divider color="white" />

            <Box>
                <Paper sx={{ width: '100%', overflow: 'hidden', bgcolor: 'transparent' }} >
                    <TableContainer className='scrollbar'>
                        <MuiTable stickyHeader aria-label="sticky table">
                            <TableHead >
                                <TableRow>
                                    {netPositionColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rowData.beforeNetPLRowData.map((row, i) => {
                                        return (
                                            <TableRow hover tabIndex={-1} key={i}>
                                                {netPositionColumns.map((column) => {
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            {...column.cellAttr || {}}
                                                        >
                                                            {column.format
                                                                ? column.format(row[column.id], row, column)
                                                                : row[column.id]
                                                            }
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }

                                {/* row:total  */}
                                <TableRow hover tabIndex={-1}>
                                    <TableCell colSpan={2}> <Typography fontWeight={"bold"}>Total</Typography> </TableCell>
                                    {
                                        netPositionColumns.map((column) => {
                                            if (!(column.id in rowData.total)) return null;

                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    <Typography fontWeight={"bold"}>

                                                        {
                                                            ["netQty", "buyQty", "sellQty"].includes(column.id) 
                                                            ? formatePrice(rowData.total[column.id], 0) 
                                                            : formatePrice(rowData.total[column.id], 2)
                                                        }
                                                    
                                                    </Typography>
                                                </TableCell>
                                            )
                                        })
                                    }

                                </TableRow>

                                {/* row:net p&l  */}
                                <TableRow hover tabIndex={-1}>
                                    <TableCell colSpan={2}> <Typography fontWeight={"bold"}>Net P&L</Typography> </TableCell>
                                    <TableCell align="center"> <Typography fontWeight={"bold"}>{formatePrice(rowData.total.mtm)}</Typography> </TableCell>
                                    <TableCell colSpan={7}>
                                        {
                                            isNetPosHidden ? 
                                            <Button
                                                variant="contained"
                                                size="small"
                                                sx={{ textTransform: "initial", p: 0 }}
                                                onClick={() => setRowData({ ...rowData, isAfterNetPLRowDataVisible: !rowData.isAfterNetPLRowDataVisible })}
                                            >
                                                {rowData.isAfterNetPLRowDataVisible ? "Hide" : "Cost"}
                                            </Button>
                                            : ""
                                        }
                                    </TableCell>
                                </TableRow>

                                {
                                    rowData.isAfterNetPLRowDataVisible && isNetPosHidden ?
                                        rowData.afterNetPLRowData.map((row = [], index) => {
                                            const isSingleRow = row.length <= 1
                                            return (
                                                <TableRow hover key={index}>
                                                    {
                                                        row.map((data, i) => {
                                                            return (
                                                                <Fragment key={data.symbol}>
                                                                    <TableCell colSpan={2}>{data.symbol}</TableCell>
                                                                    <TableCell colSpan={3 + (isSingleRow ? 5 : 0)} align={ i ? "right" : "right"}>{formatePrice(data.value)}</TableCell>
                                                                    <TableCell></TableCell>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                            )
                                        })
                                        : null
                                }

                            </TableBody>
                        </MuiTable>
                    </TableContainer>
                </Paper>
            </Box>

        </Box>
    )
}