import DeleteForever from "@mui/icons-material/DeleteForever";
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Container, Divider } from "@mui/material";
import EnableDisableSwitchElem from "helpers/Switches";
import { useUrlParams } from "utils/useUrlParams";
import Typography from "@mui/material/Typography";
import Refresh from "@mui/icons-material/Refresh";
import { formatePrice } from "utils/formatPrice";
import FormGroup from '@mui/material/FormGroup';
import TextField from "@mui/material/TextField";
import { constant } from "Root/Common/constant";
import { Download } from "@mui/icons-material";
import Save from "@mui/icons-material/Save";
import { useEffect, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import { mToast } from "Root/Common/Toast";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import moment from "moment/moment"
import { root } from "Root/root";

// userObj = {
//     "userInfo": {
//       "id": 3,
//       "fullName": "Sophia Smith",
//       "email": "5vt0mkht@hotmail.com",
//       "phone": "01668186891",
//       "userName": "jvLaMHTS"
//     },
//     "kycInfo": {
//       "gstName": "Sophia Smith",
//       "gstNumber": "597085134060025",
//       "address": "Main Street, Philadelphia, CA 19101"
//     },
//     "bankInfo": {
//       "accName": "Sophia Smith",
//       "accNumber": "4264347117",
//       "bankName": "Citizens National",
//       "ifscCode": "MMEW0622838"
//     },
//     "brokerInfo": {
//       "apiType": "MOTILAL",
//       "apiKey": "nfCl7VBRbkfaJIhGm3y7",
//       "apiSecret": "jyKCAaf6qBkMzSL31viY0jqQAWG8Od",
//       "brokeName": "Zerodha",
//       "marginVal": 75554600,
//       "mfaType": "Text Number",
//       "totp": "293991",
//       "url": "https://trade.minstein.com/"
//     }
//   }


// onSaveObj={
//     "full_name": "string",
//     "email": "string",
//     "phone": "string",
//     "user_name": "string",
//     "password": "string",
//     "gst_name": "string",
//     "gst_number": "string",
//     "address": "string",
//     "acc_name": "string",
//     "acc_number": "string",
//     "bank_name": "string",
//     "ifsc_code": "string",
//     "api_type": "string",
//     "api_key": "string",
//     "api_secret": "string",
//     "broker_name": "string",
//     "margin_val": 0,
//     "mfa_type": "string",
//     "totp": "string",
//     "url": "string"
//   }


function useInputsSchema(searchParams, setSearchParams, mStartTime, mEndTime) {
    const [schema, setSchema] = useState([
        {
            heading: "User Information",
            key: "userInfo",
            bullets: [],
            inputs: [
                { label: "Full Name", key: "fullName", optional: false, disableInMkt: false, showEdButton: false, isEdDisabled: false },
                // { label: "Email", key: "email", optional: true, disableInMkt: false, showEdButton: false, isEdDisabled: false },
                // { label: "Phone Number", key: "phone", optional: true, disableInMkt: false, showEdButton: false, isEdDisabled: false },
                { label: "Username", key: "userName", optional: false, disableInMkt: false, showEdButton: false, isEdDisabled: false },
                // { label: "Password", key: "password", type: "password", optional: false, disableInMkt: false, showEdButton: false, isEdDisabled: false },
            ]
        },
        // {
        //     heading: "KYC",
        //     key: "kycInfo",
        //     bullets: [],
        //     inputs: [
        //         { label: "GST Name", key: "gstName", optional: true, disableInMkt: false, showEdButton: false, isEdDisabled: false },
        //         { label: "GST Number", key: "gstNumber", optional: true, disableInMkt: false, showEdButton: false, isEdDisabled: false },
        //         { label: "Address", key: "address", optional: true, disableInMkt: false, showEdButton: false, isEdDisabled: false },
        //     ]
        // },
        // {
        //     heading: "Bank Details",
        //     key: "bankInfo",
        //     bullets: [],
        //     inputs: [
        //         { label: "A/c Name", key: "accName", optional: true, disableInMkt: false, showEdButton: false, isEdDisabled: false },
        //         { label: "A/c Number", key: "accNumber", optional: true, disableInMkt: false, showEdButton: false, isEdDisabled: false },
        //         { label: "Bank Name", key: "bankName", optional: true, disableInMkt: false, showEdButton: false, isEdDisabled: false },
        //         { label: "IFSC", key: "ifscCode", optional: true, disableInMkt: false, showEdButton: false, isEdDisabled: false },
        //     ]
        // },
        {
            heading: "Broker Information",
            key: "brokerInfo",
            bullets: [
                "You can update 'API Key' & 'API Secret' only before 09:00:00 or after 15:30:00."
            ],
            inputs: [
                { label: "API Type", key: "apiType", optional: false, disableInMkt: false, showEdButton: false, isEdDisabled: false },
                { label: "API Key", key: "apiKey", optional: false, disableInMkt: true, showEdButton: false, isEdDisabled: false },
                { label: "API Secret", key: "apiSecret", optional: false, disableInMkt: true, showEdButton: false, isEdDisabled: false },
                // { label: "TOTP", key: "totp", optional: true, disableInMkt: false, showEdButton: false, isEdDisabled: false },
                { label: "URL", key: "url", optional: false, disableInMkt: false, showEdButton: false, showEdButton: false, isEdDisabled: false },
                // { label: "2FA", key: "mfaType", optional: true, disableInMkt: false, showEdButton: false, isEdDisabled: false },
                { label: "Broker Name", key: "brokerName", optional: false, disableInMkt: false, showEdButton: false, isEdDisabled: false },
                // { label: "Pass", key: "brokerPass", type: "password", optional: true, disableInMkt: false, showEdButton: false, isEdDisabled: false },
                { label: "Dealer ID/Login ID/*****", key: "dealerId", optional: true, disableInMkt: false, showEdButton: true, isEdDisabled: false },
                { label: "Client ID", key: "brokerUserId", optional: true, disableInMkt: false, showEdButton: true, isEdDisabled: false },
            ]
        },
        {
            heading: "MTM",
            key: "mtm",
            bullets: [],
            inputs: [
                { label: "Limit MTM", key: "limitMtm", type: "checkbox", value: false, optional: false, disableInMkt: false, showEdButton: false, isEdDisabled: false },
                { label: "Min MTM", key: "minMtm", type: "number", value: 0, optional: false, disableInMkt: false, isNotVisible: true, InputProps: { inputProps: { max: 0, min: -999999 } }, showEdButton: false, isEdDisabled: false },
                { label: "Max MTM", key: "maxMtm", type: "number", value: 0, optional: false, disableInMkt: false, isNotVisible: true, InputProps: { inputProps: { min: 0 } }, showEdButton: false, isEdDisabled: false },
                { label: "Include Manual", key: "inclManual", type: "checkbox", value: false, optional: false, disableInMkt: false,
                    toolTipText: "Whether to include the manual trades while calculating MTM for the limit check.", showEdButton: false, isEdDisabled: false
                },
            ]
        },
        {
            heading: "Other",
            key: "other",
            bullets: [],
            inputs: [
                { label: "Is Pro Account", key: "isProAcc", type: "checkbox", value: false, optional: false, disableInMkt: false, showEdButton: false, isEdDisabled: false },
            ]
        }
    ]);
    const [apiData, setApiData] = useState()

    const showHideMtm = (isVisible=true) => {
        schema.forEach(sch => {
            if (sch.key === "mtm") {
                sch.inputs.forEach(schInp => {
                    if (["minMtm", "maxMtm", "inclManual"].includes(schInp.key)) {
                        schInp.isNotVisible = isVisible
                    }
                })
            }
        })
    }

    const onChangeInputs = (e, schemaIndex, inputIndex) => {
        const schemaObj = schema[schemaIndex].inputs[inputIndex]

        if (schemaObj.type === "checkbox") {
            schemaObj.value = e.target.checked

            if (schemaObj.key === "limitMtm") {
                showHideMtm(!schemaObj.value)
            }
        }
        else {
            schemaObj.value = e.target.value
        }

        setSchema([...schema])
    }

    const onSumbit = () => {
        const payload = {}
        const cTime = moment();
        
        const isEdit = searchParams.get("type") === "edit"

        if (!isEdit & (cTime.isBetween(mStartTime, mEndTime))) {
            mToast.error("Couldn't create a new user in-between market hours!")
            return
        }
        
        for (let section of schema) {
            for (let input of section.inputs) {
                if ("key" in input) {
                    payload[input.key] = input.value
                }
            }
        }
        
        // if (payload.limitMtm) {
        //     if (payload.minMtm >= 0) {
        //         mToast.error("Minimum MTM should be less than 0!")
        //         return
        //     }
        //     if (payload.maxMtm <= 0) {
        //         mToast.error("Maximum MTM should be greater than 0!")
        //         return
        //     }
        // }

        root.apis.users[isEdit ? "edit" : "register"](...[isEdit && apiData.userInfo.id, payload].filter(e => e))
            .then((data) => {
                mToast.success(data.data.msg)
                setSearchParams()
            })
            .catch(err => {
                if (err.response) {
                    err = err.response.data.err
                }
                else {
                    err = err.statusText
                }

                mToast.error(err)
            })

    }

    useEffect(() => {
        if (searchParams.get("type") === "edit") {
            const userId = searchParams.get("userid")

            if (userId) {
                root.apis.users.get(userId)
                    .then(d => {
                        console.log("userid, getUser response = ", userId, d);

                        const assignDataToSchema = (prevSchema) => {
                            var isVisibleMtm = false

                            prevSchema.forEach(section => {
                                const sectionObj = d.data[section.key]

                                if (sectionObj) {
                                    section.inputs.forEach(input => {
                                        const value = sectionObj[input.key]

                                        if (value) {
                                            input.value = value
                                        }
                                        
                                        if (input.key === "limitMtm") {
                                            isVisibleMtm = input.value
                                        }
                                    })
                                }
                            })

                            showHideMtm(!isVisibleMtm)

                            return [...prevSchema]
                        }

                        if ("err" in d.data) {
                            mToast.error(d.data.err)
                        } else {
                            setSchema(assignDataToSchema)
                            setApiData(d.data)
                        }
                    })
                    .catch(e => {
                        console.log('userid, getUser error = ', userId, e)
                        mToast.error(e.statusText)
                    })
            } else {
                mToast.info("UserId is required for edit.")
            }
        }
    }, [searchParams.get("type")])


    return [schema, setSchema, onChangeInputs, onSumbit, apiData]
}

function SaveButtonAndMarginValue({ apiData, searchParams, setSearchParams, onSumbit, schema }) {
    const [marginData, setMarginData] = useState({
        value: 0,
        refreshValue: 0, // when data will fetched from refresh
        isFetching: false
    })

    const onRefreshMargin = () => {
        if (marginData.isFetching) return;
        
        const brokerInfo = {};
        var payload = null;

        for (let section of schema) {
            if (section.key === "brokerInfo") {
                for (let input of section.inputs) {
                    if ("key" in input) {
                        brokerInfo[input.key] = input.value
                    }
                }
            }
        }

        if (apiData) {
            payload = { userId: apiData.userInfo.id }
        }
        else {
            payload = {
                apiCreds: {
                    apiType: brokerInfo.apiType,
                    apiHost: brokerInfo.url,
                    apiKey: brokerInfo.apiKey,
                    apiSecret: brokerInfo.apiSecret,
                    brokerUserId: brokerInfo.brokerUserId
                }
            }
        }

        if (payload) {
            setMarginData({ ...marginData, isFetching: true })
            root.apis.users.refreshMargin(payload)
                .then(d => {
                    console.log('refresh margin response', d)
                    const data = {}
                    if ("updatedVal" in d.data) {
                        data.refreshValue = d.data.updatedVal
                        data.value = d.data.updatedVal
                    } else {
                        mToast.error(d.err)
                    }

                    setMarginData({
                        ...marginData,
                        ...data,
                        isFetching: false
                    })
                })
                .catch(e => {
                    console.log("refresh margin error", e)
                    mToast.error(e.response.statusText)

                    setMarginData({ ...marginData, isFetching: false })
                })
        }
    }

    useEffect(() => {
        if (apiData) {
            console.log("apid data", apiData)
            setMarginData((prev) => ({
                ...prev,
                value: Number(apiData?.brokerInfo?.marginVal || 0)
            }))
        }
    }, [apiData])

    return (
        <Box display={"flex"} justifyContent={"end"} my={2}>
            <Box>
                {/* {searchParams.get("type") === "edit" ? */}
                <Box display={'flex'} alignItems={"center"}>
                    <Typography style={{ fontSize: 13 }}>Margin Val.&nbsp;</Typography>
                    <Typography style={{ fontSize: 15, fontWeight: 'bold' }}>{formatePrice(marginData.value)}&nbsp;</Typography>
                    <Button
                        startIcon={marginData.isFetching ? <Download /> : <Refresh />}
                        variant="text"
                        style={{ textTransform: 'initial' }}
                        onClick={onRefreshMargin}
                    >
                        {marginData.isFetching ? "Fetching..." : "Refresh"}
                    </Button>
                </Box>
                    {/* : null
                } */}

                <Box display={'flex'} justifyContent={'end'} gap={5} mt={2}>
                    <Button
                        variant="outlined"
                        style={{ textTransform: 'initial', color: '#707070' }}
                        onClick={() => {
                            setSearchParams()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<Save />}
                        onClick={onSumbit}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Box>

    )
}

function DeleteAccountSection({ apiData, searchParams, setSearchParams }) {
    const [isDeleteCall, setIsDeleteCall] = useState(false)

    if (!apiData || searchParams.get("type") !== "edit" || !apiData.userInfo?.id) return null;


    const onDelete = () => {
        if (apiData && apiData.userInfo.id) {
            if (!window.confirm("Are you sure do you want to delete your account?")) return;
            setIsDeleteCall(true)

            root.apis.users.delete(apiData.userInfo.id)
                .then(d => {
                    mToast.success(d.data.msg)
                    setSearchParams()
                })
                .catch(e => {
                    mToast.error(e.statusText)
                })
                .finally(() => {
                    setIsDeleteCall(false)
                })
        }
    }

    return (
        <Box mt={2}>
            <Box mb={1}>
                <Typography>
                    Delete Account
                </Typography>
            </Box>
            <Button
                startIcon={<DeleteForever />}
                variant="outlined"
                color="error"
                style={{ textTransform: 'initial' }}
                onClick={onDelete}
            >
                {isDeleteCall ? "Delete your account..." : "Remove Account"}
            </Button>
        </Box>
    )
}

export function CreateNewUser() {
    const [searchParams, , setSearchParams] = useUrlParams()

    const cMarket = constant.Market
    const mStartTime = moment(cMarket.MStartTime, "HH:mm:ss")
    const mEndTime = moment(cMarket.MEndTime, "HH:mm:ss")
    
    const [schema, setSchema, onChangeInputs, onSumbit, apiData] = useInputsSchema(searchParams, setSearchParams, mStartTime, mEndTime)
    const [, setIsPermsChecked] = useState(true);

    const cTime = moment();

    const EnableDisableSwitch = ({ initialValue, onChangeInputs, schemaIndex, inputIndex, isChecked }) => {
        useEffect(() => {
            setIsPermsChecked(initialValue);
        }, [initialValue])  
      
        const handleChange = (e) => {
            setIsPermsChecked(e.target.checked);
            onChangeInputs(e, schemaIndex, inputIndex);
        };
      
        return (
            <EnableDisableSwitchElem
                checked={isChecked}
                onChange={handleChange}
            />
        );
    };

    const DisableTextWithEDSwitch = ({ initialValue, schemaIndex, inputIndex, isChecked }) => {
        const schemaObj = schema[schemaIndex].inputs[inputIndex]

        const handleChange = (e) => {
            schemaObj.isEdDisabled = !isChecked
            setSchema([...schema])
        };
      
        return (
            <EnableDisableSwitchElem
                checked={isChecked}
                onChange={handleChange}
            />
        );
    };

    return (
        <Container disableGutters style={{ position: 'relative' }}  >
            <Box
                sx={{
                    width: '100%',
                }}
                className="custom-inputs"
                noValidate
                autoComplete="off"
            >
                {
                    schema.map((schema = {}, schemaIndex) => {

                        return (
                            <Box
                                key={schema.heading}
                                sx={{
                                    mt: schemaIndex ? 3 : 0
                                }}
                            >
                                <Box mb={1}>
                                    <Typography style={{
                                        fontSize: 18,
                                        textDecoration: 'underline',
                                        textUnderlineOffset: 5,
                                        color: "#5497db"
                                    }}>
                                        {schema.heading}
                                    </Typography>
                                </Box>

                                <Box mb={2}>
                                    {
                                        schema.bullets.map(bullet => {
                                            return (
                                                <Typography display="flex">
                                                    <Typography color="red">*</Typography> 
                                                    <Typography ml={1}>{bullet}</Typography>
                                                </Typography>
                                            )
                                        })
                                    }
                                </Box>
                                
                                <Box>
                                    <Grid container spacing={{ xs: 2, md: 4 }}>
                                        {
                                            schema.inputs.map(({isNotVisible, ...input}, inputIndex) => {

                                                if (isNotVisible) return

                                                if (!input.optional) {
                                                    input.label = input.label + " *"
                                                }

                                                if (input.disableInMkt) {
                                                    if (cTime.isBetween(mStartTime, mEndTime)) {
                                                        input.isDisabled = true
                                                    }
                                                    else {
                                                        input.isDisabled = false
                                                    }
                                                }
                                                else {
                                                    input.isDisabled = false
                                                }

                                                return (
                                                    <Grid
                                                        key={input.label}
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                    >

                                                        {
                                                            input.type === "checkbox" ?
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <EnableDisableSwitch 
                                                                            initialValue={input.value} onChangeInputs={onChangeInputs} 
                                                                            schemaIndex={schemaIndex} inputIndex={inputIndex} 
                                                                            isChecked={input.value}
                                                                        />
                                                                    }
                                                                    label={
                                                                        <Box display="flex">
                                                                            {input.label}
                                                                            <Box ml={1} mt={0.5}>
                                                                                {
                                                                                    input.toolTipText ?
                                                                                    <Tooltip title={input.toolTipText}> 
                                                                                        <HelpOutlineIcon fontSize="small" />
                                                                                    </Tooltip>    
                                                                                    : ""
                                                                                }
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                />
                                                            </FormGroup>
                                                            :
                                                            <Box display="flex" alignItems="center">
                                                                <TextField
                                                                    fullWidth
                                                                    onChange={(e) => { onChangeInputs(e, schemaIndex, inputIndex) }}
                                                                    value={input.value ?? ""}
                                                                    variant="standard"
                                                                    disabled={
                                                                        input.showEdButton ? !input.isEdDisabled : input.isDisabled
                                                                    }
                                                                    {...input}
                                                                />

                                                                {
                                                                    input.showEdButton
                                                                    ? <Box ml={1}>
                                                                        <FormGroup>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <DisableTextWithEDSwitch 
                                                                                        initialValue={input.isEdDisabled}
                                                                                        schemaIndex={schemaIndex}
                                                                                        inputIndex={inputIndex} 
                                                                                        isChecked={input.isEdDisabled}
                                                                                    />
                                                                                }
                                                                                sx={{
                                                                                    margin: 0
                                                                                }}
                                                                            />
                                                                        </FormGroup>
                                                                    </Box>
                                                                    : ""
                                                                }
                                                            </Box>
                                                        }

                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Box>
                            </Box>
                        )
                    })
                }

                <SaveButtonAndMarginValue
                    apiData={apiData}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    onSumbit={onSumbit}
                    schema={schema}
                />

                <Divider color="gray" />

                <DeleteAccountSection
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    apiData={apiData}
                />
            </Box>
        </Container>
    )
}