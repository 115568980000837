import { Box, IconButton, Typography, Button } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState, forwardRef, useRef } from 'react';
import { OrderStatusList } from "utils/OrderStatusList";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Close from '@mui/icons-material/Close';
import { mToast } from 'Root/Common/Toast';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import Slide from '@mui/material/Slide';
import { Table } from "helpers/Table";
import { root } from 'Root/root';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SSConfirmModal({ open = false, handleClose = () => undefined, stgId = undefined, stgName = undefined, isPsStart = undefined }) {
	const [stgConfigs, setStgConfigs] = useState([])
	const constantRef = useRef({
        isStartStopProcessRequestInProgress: false,
    })

    const commonSel = useSelector(selector => selector.common)
    const loginId = commonSel.loginId

	const stgCfgCols = [
		{ id: "userId", label: "User ID", align: "center", format(value, row={}) { 
			return <Box color="black">( {value} ) {row.fullName}</Box> 
		}},
		{ id: "status", label: "Status", align: "center", format(value) { 
			return <Box
				key={value}
				display="flex"
				alignItems="center"
				gap={1}
				justifyContent="center"
			>
				<Box
					sx={{
						width: 10,
						height: 10,
						// aspectRatio: "1/1",
						borderRadius: "100%",
						bgcolor: value ? OrderStatusList[value]?.color[800] : "transparent"
					}}
				/>

				<Typography color="black">
					{ OrderStatusList[value].name }
				</Typography>
			</Box>
		}},
		{ id: "lots", label: "Lots", align: "center", format(value) { return <Box color="black">{value}</Box> }},
	]

	// Get order config details for every user
	useEffect(() => {
		if (stgId) {
			root.apis.orderConfig.getStgConfig(stgId, { "filtActiveLots": true })
			.then((data) => {
				setStgConfigs(data.data.stgConfigs)
			})
			.catch((err) => {
				console.log("Error getStgConfig:", err)
			})
		}
	}, [stgId])

	const handleStartStopProcessButton = (isStart, propTag=null) => {
        return new Promise((resolve)=>{
            if(constantRef.current.isStartStopProcessRequestInProgress){
                resolve(false)
                return
            }

            if (stgId) {
                var payload = {
                    loginId: loginId, 
                    stgId: stgId
                }

                if (propTag) {
                    payload.propTag = propTag
                }

                constantRef.current.isStartStopProcessRequestInProgress = true

                root.apis.strategy
                .startStopProcess(payload, isStart)
                .then(d => {
                    if (d.data.msg) {
                        mToast.success(d.data.msg)
                    }
        
                    if (d.data.err) {
                        mToast.error(d.data.err)
                    }

                    resolve(true)
					window.location.reload();
                })
                .catch(err => {
                    const err_resp = err.response
        
                    if (err_resp.status === 403) {
                        mToast.error(err_resp.data.err)
                    }
                    else {
                        mToast.error(err.message)
                    }

                    resolve(false)
                })
                .finally(() => {
                    constantRef.current.isStartStopProcessRequestInProgress = false
                })
            }
        })
    }

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			PaperProps={{
				sx: {
					minWidth: "40%",
					minHeight: "20%",
					color: "#000"
				}
			}}
		>
			<DialogTitle>
				<Box display="flex" alignContent="center" justifyContent="space-between">
					<Box>{isPsStart ? "Start" : "Stop"} '{stgName}' Strategy Process</Box>
					<Box><IconButton onClick={handleClose}><Close /></IconButton></Box>
				</Box>
			</DialogTitle>

			<DialogContent dividers>
				<Box display="flex">
					<Box color="red" mr={1.5}>*</Box> 
					<Box display="flex">
						<Typography>Your action will affect the following user configurations:</Typography>
					</Box>
				</Box>

				<Box p={2} sx={{
					textColor: "#fff"
				}}>
					<Table
						columns={stgCfgCols}
						rows={stgConfigs}
					/>
				</Box>
			</DialogContent>

			<DialogActions>
				<Box sx={{ textAlign: "center", width: "100%" }}>

					<Box display="flex" justifyContent="center" >
						<Button
							variant="contained"
							color={isPsStart ? "success" : "error"}
							onClick={() => { 
								isPsStart ? handleStartStopProcessButton(true) : handleStartStopProcessButton(false, `process-kill-${loginId}`);
								handleClose();
							}}
						>
							Confirm
						</Button>
					</Box>
				
				</Box>
			</DialogActions>
		</Dialog>
	);
}