// import { NetPosition as CNetPosition, netPositionColumns } from "./../common/NetPosition"
import { NetPositionWithXTS, netPositionColumns } from "../common/NetPositionWithXTS";
import { DigitFormatter } from "helpers/DigitFormatter";
import { useEffect, useState } from "react";
import { mToast } from "Root/Common/Toast";
import { useSelector } from 'react-redux';
import { root } from "Root/root";

export function NetPosition({ userId, searchParams, socketFunHandler, setActivePosTab }) {
    const [netRowData, setNetRowData] = useState({
        beforeNetPLRowData: [],
        total: { mtm: 0, netQty: 0, buyQty: 0, avgBuyPrice: 0, sellQty: 0, avgSellPrice: 0, buyVal: 0, sellVal: 0 },
        afterNetPLRowData: [],
        isAfterNetPLRowDataVisible: false
    })
    const [xtsRowData, setXtsRowData] = useState({
        beforeNetPLRowData: [],
        total: { mtm: 0, netQty: 0, buyQty: 0, avgBuyPrice: 0, sellQty: 0, avgSellPrice: 0, buyVal: 0, sellVal: 0 },
        afterNetPLRowData: [],
        isAfterNetPLRowDataVisible: false
    })

    const commonSel = useSelector((state)=> state.common)
    const loginId = commonSel.loginId

    // const createRows = (symbol, ltp, mtm, netQty, buyQty, avgBuy, qtySell, avgSell, buyVal, sellVal) => {
    //     return { symbol, ltp, mtm, netQty, buyQty, avgBuy, qtySell, avgSell, buyVal, sellVal }
    // }

    const createPosData = (posData, total) => {
        // const total = Object.keys(rowData.total).reduce((prev, curr)=>({ ...prev, [curr]: 0 }), []) // use the for loop (it will increase the performace), if there are socket.
        // const total = posData.total

        const beforeNetPLRowData = (posData || []).map((pos)=>{
            const col = {}

            col["userId"] = userId
            col["loginId"] = loginId
            col["brokerUserId"] = searchParams.get("brokerUserId")
            col["excInstId"] = pos.excInstId
            col["stgId"] = searchParams.get("strategyId")

            for(let column of netPositionColumns){
                col[column.id] = pos[column.id]
                col["isXtsCol"] = false

                // if(column.id in total){
                //     total[column.id]+= pos[column.id] || 0
                // }

            }
            return col
        })

        setNetRowData(prev=>({
            ...prev,
            beforeNetPLRowData,
            total
        }))
    }

    const createXTSPosData = (posData, total) => {
        const beforeNetPLRowData = (posData || []).map((pos)=>{
            const col = {}

            col["userId"] = userId
            col["loginId"] = loginId
            col["brokerUserId"] = searchParams.get("brokerUserId")
            col["excInstId"] = pos.excInstId

            for(let column of netPositionColumns){
                col[column.id] = pos[column.id]
                col["isXtsCol"] = true
            }

            return col
        })

        setXtsRowData(prev=>({
            ...prev,
            beforeNetPLRowData,
            total
        }))
    }

    socketFunHandler.current.posData = (data) => {
        document.title = `${DigitFormatter(data.total.mtm, 2)} | ${searchParams.get("fullName")}`;

        createPosData(data.pos, data.total)
        createXTSPosData(data.xtsPos, data.xtsTotal)
        // setRowData(data)
    }

    // in api, mission data don't delete this comment. helpful for data structure
    // useEffect(() => {
    //     const beforeNetPLRowData = [
    //         createRows("NIFTY180", 200, 123.34, true, 50, true, 200, 0, 0, 0, 10000, 0),
    //         createRows("NIFTY180", 200, 123.34, false, 50, true, 200, 0, 0, 10, 10000, 0),
    //         createRows("NIFTY180", 200, 123.34, true, 50, true, 200, 0, 0, 40, 10000, 0),
    //         createRows("NIFTY180", 200, 123.34, false, 50, false, 200, 0, 0, 0, 10000, 0),
    //     ]
    //     const afterNetPLRowData = [
    //         [
    //             { symbol: "Trunover", value: 23893 },
    //             { symbol: "Brokerage", value: 0 },
    //         ],
    //         [
    //             { symbol: "STT Total", value: 2 },
    //             { symbol: "Exchange txn charges", value: 20 },
    //         ],
    //         [
    //             { symbol: "Clearing Charge", value: 23 },
    //             { symbol: "GST", value: 9 },
    //         ],
    //         [
    //             { symbol: "Stamp Duty", value: 67 },
    //             { symbol: "SEBI Charges", value: 1 },
    //         ],
    //         [
    //             { symbol: "Trunover", value: 1000 },
    //             { symbol: "Brokerage", value: 0.3 },
    //         ],
    //         [
    //             { symbol: "STT total", value: 89 },
    //             { symbol: "Exchange txn charges", value: 0.1 },
    //         ],
    //         [
    //             { symbol: "Total Tax", value: 10000 }
    //         ],
    //         [
    //             { symbol: "Long Premium", value: 1000 },
    //             { symbol: "Short Premium", value: 1000 },
    //         ],
    //         [
    //             { symbol: "CE Qty", value: 10000 },
    //             { symbol: "PE Qty", value: 40000 },
    //         ]
    //     ]
    //     setRowData(prev => ({ ...prev, beforeNetPLRowData, afterNetPLRowData }))
    // }, [])

    useEffect(()=>{
        if (userId) {
            root.apis.positions.netSummary(userId) // replace 2 with userId
            .then(d=>{
                const netSummary = []
                var i = 1
                var temp = []
                for(let summary of (d.data || [])){
                    temp.push({ symbol: summary.name, value: summary.value, key: summary.key })
                    if(!(i%2)){
                        netSummary.push(temp)
                        temp = []
                    } 
                    i++
                }
                if(temp.length)netSummary.push(temp)

                setNetRowData(prev => ({ ...prev, afterNetPLRowData: netSummary }))
            })
            .catch(e => {
                mToast.error(e.message)
            })
        }
    }, [userId])

    return (
        <NetPositionWithXTS 
            netRowData={netRowData} 
            xtsRowData={xtsRowData}
            setActivePosTab={setActivePosTab}
        />
    )
}