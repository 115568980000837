import { tradeBookColumns, orderBookColumns, bOrderBookColumns, OrderBook as COrderBook } from "components/body/positions/common/OrderBook";
import { useState, useEffect } from "react";
import { mToast } from "Root/Common/Toast";
import { Box } from "@mui/material";
import { root } from "Root/root";

export function TradeBook({ cfgId, socketFunHandler }) {
    const [isHiddenOrderBookList, setIsHiddenOrderBookList] = useState(false)
    const [isHiddenBOrderBookList, setIsHiddenBOrderBookList] = useState(false)
    const [isHiddenTradeBookList, setIsHiddenTradeBookList] = useState(false)
    const [orderCount, setOrderCount] = useState(0)
    const [bOrderCount, setBOrderCount] = useState(0)
    const [tradeCount, setTradeCount] = useState(0)
    const [orderBookData, setOrderBookData] = useState([])
    const [bOrderBookData, setBOrderBookData] = useState([])
    const [tradeBookData, setTradeBookData] = useState([])

    const createRows = (ob={}, cols, showStatusInfo, showCumQty, showTagInfo, includeCols=[]) => {
        const col = {}

        col["status"] = ob.status

        if (showCumQty) {
            col["cumQty"] = ob.cumQty
        }

        if (showTagInfo) {
            col["tagInfo"] = ob.tagInfo
        }

        if (showStatusInfo) {
            col["statusInfo"] = ob.statusInfo
        }

        // Include columns for the propbook
        if (includeCols.length > 0) {
            for (var key in ob) {
                if (includeCols.includes(key)) {
                    col[key] = ob[key]
                }
            }
        }

        cols.forEach(({ id })=>{
            col[id] = ob[id]
        })

        return col
    }

    // socketFunHandler.current.newTrade = (data) => {
    //     mToast.success(data.stg, {
    //         style: { width: "200px", float: "right" }
    //     })
        
    //     setTradeCount(tradeCount + 1)
    //     setTradeBookData([createRows(data, tradeBookColumns, true, false, false), ...tradeBookData])
    // }

    // socketFunHandler.current.newOrder = (data) => {
    //     setOrderCount(orderCount + 1)
    //     setOrderBookData([createRows(data, orderBookColumns, true, false, true, ["exchange", "mType", "qty"]), ...orderBookData])
    // }

    // socketFunHandler.current.newBOrder = (data) => {
    //     setBOrderCount(bOrderCount + 1)
    //     setBOrderBookData([createRows(data, bOrderBookColumns, true, true, false), ...bOrderBookData])

    //     if (data.status === "rejected") {
    //         mToast.warning(data.statusInfo, {
    //             style: { width: "400px", float: "right" }
    //         })
    //     }
    // }

    useEffect(()=>{
        if (cfgId) {
            root.apis.strategyInstance.tradeBook(cfgId)
            .then(d => {
                setOrderCount(d.data.orderCount)
                setBOrderCount(d.data.bOrderCount)
                setTradeCount(d.data.tradeCount)

                setOrderBookData((d.data.orderBook || []).map(order => createRows(order, orderBookColumns, false, false, true, ["exchange", "mType", "qty"])))
                setBOrderBookData((d.data.bOrderBook || []).map(order => createRows(order, bOrderBookColumns, true, true, false)))
                setTradeBookData((d.data.tradeBook || []).map(trade => createRows(trade, tradeBookColumns, true, false, false)))
            })
            .catch(e => {
                mToast.error(e.message)
            })
        }
    }, [cfgId])

    return (
        <Box mt={2}>
            <COrderBook
                orderCount={orderCount}
                bOrderCount={bOrderCount}
                tradeCount={tradeCount}
                orderBookData={orderBookData}
                bOrderBookData={bOrderBookData}
                tradeBookData={tradeBookData}
                isHiddenOrderBookList={isHiddenOrderBookList}
                setIsHiddenOrderBookList={setIsHiddenOrderBookList}
                isHiddenBOrderBookList={isHiddenBOrderBookList}
                setIsHiddenBOrderBookList={setIsHiddenBOrderBookList}
                isHiddenTradeBookList={isHiddenTradeBookList}
                setIsHiddenTradeBookList={setIsHiddenTradeBookList}
            />
        </Box>
    )
}