import { Box, Button, IconButton } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Typography, DialogActions } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DownloadIcon from '@mui/icons-material/Download';
import { useMemo, forwardRef, useState, useRef } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Close from "@mui/icons-material/Close";
import { mToast } from "Root/Common/Toast";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { root } from "Root/root";

export function SystemRestartDialog({
    open = false, onClose = () => undefined, setIsRestartSysModalOpen = () => undefined
}) {
    const Transition = useMemo(() => forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    }), [])

    const handleOnRestart = () => {
        root.apis.monitor.restart()
        .then((resp) => {
            if (resp.status === 201) {
                mToast.success(resp.data.msg)

                setIsRestartSysModalOpen(false)
            }
            else {
                mToast.error(resp.data.detail)
            }
        })
        .catch((err) => {
            const err_resp = err.response

            if (err_resp) {
                if (err_resp.status === 403) {
                    mToast.error(err_resp.data.detail)
                }
                else {
                    mToast.error("Couldn't complete request. Something went wrong!")
                }
            }
            else {
                mToast.error(err.message)
            }
        })
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => { }}
            scroll="paper"
            maxWidth="md"
            PaperProps={{
                sx: {
                    minHeight: "20%",
                    minWidth: "20%",
                    color: "#000",
                    "& table": {
                        "thead tr th": {
                            p: 2
                        },
                        "tbody tr td": {
                            color: "#000",
                            py: 2
                        }
                    },
                    "& button": {
                        textTransform: "initial"
                    },
                    "& .mk-flex": {
                        mt: 3,
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 2,
                        "input": {
                            color: "black",
                        },
                    }
                }
            }}
        >
            <DialogTitle>
                <Box display="flex" alignContent="center" justifyContent="space-between">
                    <Box>Restart System</Box>
                    <Box display="flex" alignContent="center">
                        <Box ml={2}><IconButton onClick={onClose}><Close /></IconButton></Box>
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent>
                <Box>
					<Box display="flex">
					    <Box color="red" mr={1.5}>*</Box> 
						<Typography fontWeight="bold">Are you sure you want to restart the system?</Typography>
					</Box>

                    <Box> 
                        <ul>
                            <li>No new trades will be executed, but existing positions in both manual and automated strategies will remain open.</li>
                            <li>All strategies will be halted and restarted with fresh positions.</li>
                        </ul>
                    </Box>
				</Box>
            </DialogContent>

            <DialogActions>
                 <Box
                    sx={{
                        width: "100%",
                        px: 2
                    }}
                >
                    <Box className="mk-flex" style={{ justifyContent: "end" }}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <Button 
                                onClick={() => {setIsRestartSysModalOpen(false)}} 
                                variant="contained" 
                                color="error"
                            >
                                Cancel
                            </Button>

                            <Button 
                                onClick={handleOnRestart} 
                                variant="contained" 
                                color="success"
                            >
                                Restart
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
}