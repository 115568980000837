import { Box, IconButton, Typography, Button } from '@mui/material';
import { useEffect, useState, forwardRef, useRef } from 'react';
import DialogContent from '@mui/material/DialogContent';
import { OrderStatusList } from "utils/OrderStatusList";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Close from '@mui/icons-material/Close';
import { mToast } from 'Root/Common/Toast';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import Slide from '@mui/material/Slide';
import { Table } from "helpers/Table";
import { root } from 'Root/root';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function InstanceDeleteModal({ open = false, handleClose = () => undefined, instTag = "", instCfgId = null }) {
	const constantRef = useRef({
        isInstDelRequestInProgress: false,
    })

	const handleInstDelButton = () => {
        return new Promise((resolve)=>{
            if (constantRef.current.isInstDelRequestInProgress) {
                resolve(false)
                return
            }

            if (instCfgId) {
                constantRef.current.isInstDelRequestInProgress = true

                root.apis.strategyInstance.deleteInstance(instCfgId)
                .then(d => {
                    if (d.data.msg) {
                        mToast.success(d.data.msg)
                    }
        
                    if (d.data.err) {
                        mToast.error(d.data.err)
                    }

                    resolve(true)
					window.location.reload();
                })
                .catch(err => {
                    const err_resp = err.response
        
                    if (err_resp.status === 403) {
                        mToast.error(err_resp.data.err)
                    }
                    else {
                        mToast.error(err.message)
                    }

                    resolve(false)
                })
                .finally(() => {
                    constantRef.current.isInstDelRequestInProgress = false
                })
            }
        })
    }

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			PaperProps={{
				sx: {
					minWidth: "40%",
					minHeight: "20%",
					color: "#000"
				}
			}}
		>
			<DialogTitle>
				<Box display="flex" alignContent="center" justifyContent="space-between">
					<Box>Delete Strategy Instance: {instTag}</Box>
					<Box><IconButton onClick={handleClose}><Close /></IconButton></Box>
				</Box>
			</DialogTitle>

			<DialogContent dividers>
				<Box display="flex">
					<Box color="red" mr={1.5}>*</Box> 
					<Box display="flex">
						<Typography fontWeight="bold">If you proceed with deleting this strategy instance:</Typography>
					</Box>
				</Box>
				<Box> 
					<ul>
						<li>All open positions within this instance will be squared-off in the accounts of the users associated with it.</li>
						<li>The configurations of this instance will be deleted from server, and you will no longer have access to it.</li>
					</ul>
				</Box>
			</DialogContent>

			<DialogActions>
				<Box sx={{ textAlign: "center", width: "100%" }}>

					<Box display="flex" justifyContent="center" gap={3} >
						<Button
							variant="contained"
							color="error"
							onClick={handleClose}
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							color="success"
							onClick={() => { 
								handleInstDelButton()
								handleClose();
							}}
						>
							Confirm
						</Button>
					</Box>
				
				</Box>
			</DialogActions>
		</Dialog>
	);
}