import { Box, IconButton, Button, TextField } from '@mui/material';
import { useEffect, useState, forwardRef } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Close from '@mui/icons-material/Close';
import { mToast } from 'Root/Common/Toast';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Table } from "helpers/Table";
import { root } from 'Root/root';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function InstanceMultiplierModal({ 
	open = false, handleClose = () => undefined, instTag = "", instCfgId = null, netQty = 0
}) {
    const [mulpData, setMulpData] = useState([])
	const mulpCols = [
		{ id: "user", label: "User", align: "center", format(value) { 
			return <Box color="black" py={1}>{value}</Box> 
		}},
		{ id: "mulp", label: "Multiplier", align: "center", format(value, row={}) { 
			return (
				<TextField
					type="number"
					value={value ? value : 0}
					onChange={(e) => handleMultiplierChange(row.user_id, e.target.value)}
					inputProps={{ min: 0, step: 1 }}
					disabled={netQty !== 0}
					sx={{
						width: "80px",
						"& .MuiInputBase-input": {
							padding: "4px 8px",
							color: "black"
						}
					}}
				/>
			);
		}}
	]

	useEffect(() => {
		if (open && instCfgId) {
			root.apis.strategyInstance.getInstMulp(instCfgId)
			.then(data => {
				setMulpData(data.data.mulp || [])
			})
			.catch(err => {
				console.log("Get Instance Multiplier Error: ", err)
				mToast.error(err.statusText)
			})
		}
	}, [open, instCfgId])

	const handleMultiplierChange = (userId, value) => {
		mulpData.forEach((mulp) => {
			if (mulp.user_id === userId) {
				mulp.mulp = parseInt(value)
			}
		})

		setMulpData([...mulpData])
    };

	const handleMultiplierSave = () => {
		const mulpDataCp = JSON.parse(JSON.stringify(mulpData));
		const payload = {
			"cfg_id": instCfgId,
			"mulp_d": mulpDataCp.map(mulp => {delete mulp.user; return mulp;})
		}

		root.apis.strategyInstance.saveInstMulp(payload)
        .then((resp) => {
            if (resp.status === 201) {
                mToast.success(resp.data.msg)
				handleClose()
            }
            else {
                mToast.error(resp.data.detail)
            }
        })
        .catch((err) => {
            const err_resp = err.response

            if (err_resp) {
                if (err_resp.status === 403) {
                    mToast.error(err_resp.data.detail)
                }
                else {
                    mToast.error("Couldn't complete request. Something went wrong!")
                }
            }
            else {
                mToast.error(err.message)
            }
        })
	}

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			PaperProps={{
				sx: {
					minWidth: "30%",
					minHeight: "60%",
					color: "#000"
				}
			}}
		>
			<DialogTitle>
				<Box display="flex" alignContent="center" justifyContent="space-between">
					<Box>Instance Multiplier: {instTag}</Box>
					<Box><IconButton onClick={handleClose}><Close /></IconButton></Box>
				</Box>
			</DialogTitle>

			<DialogContent dividers>
				<Box p={2} sx={{
					textColor: "#fff"
				}}>
					<Table
						columns={mulpCols}
						rows={mulpData}
					/>
				</Box>
			</DialogContent>

			<DialogActions>
				<Box sx={{ textAlign: "center", width: "100%" }}>

					<Box display="flex" justifyContent="center" gap={3} >
						<Button
							variant="contained"
							color="error"
							onClick={handleClose}
						>
							Close
						</Button>

						<Button
							variant="contained"
							disabled={netQty !== 0}
							onClick={handleMultiplierSave}
						>
							Save
						</Button>
					</Box>
				
				</Box>
			</DialogActions>
		</Dialog>
	);
}