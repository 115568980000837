import OrderManagementEditDialog from "helpers/OrderManagementEditDialog";
import { default as MuiTableCell } from '@mui/material/TableCell';
import { topNavBarHeight } from "components/nav/AsideMenus";
import TableContainer from '@mui/material/TableContainer';
import { SectionHeading } from "helpers/SectionHeading";
import { OrderStatusList } from "utils/OrderStatusList";
import { setCookie, getCookie } from "helpers/Cookies";
import CachedIcon from '@mui/icons-material/Cached';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { StrategyModal } from "./StrategyModal";
import { constant } from "Root/Common/constant";
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from "react";
import { DropDown } from "helpers/DropDown";
import Edit from "@mui/icons-material/Edit";
import MuiTable from '@mui/material/Table';
import { Typography } from "@mui/material";
import { mToast } from "Root/Common/Toast";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { Preview } from "./Preview";
import { root } from "Root/root";

function TableCell({ children, ...attr }) {
    if (!attr.sx) attr.sx = {}
    attr.sx = { p: 0, ...attr.sx }

    return (
        <MuiTableCell {...attr}>
            {children}
        </MuiTableCell>
    )
}

const formatStgLabel = (name, isEnable) => {
    return <Box
        key={name}
        display="flex"
        alignItems="center"
        gap={1}
        justifyContent="center"
    >
        <Typography>
            { name }
        </Typography>

        <Box
            sx={{
                width: 10,
                height: 10,
                // aspectRatio: "1/1",
                borderRadius: "100%",
                bgcolor: isEnable ? "green" : "red"
            }}
        />
    </Box>
}

const calcTotal = (data, total) => {
    for (let key in data) {
        if (key in total) {
            total[key] += Number(data[key])
        }
    }
}

function useGetData(userList=[], strategyList=[], socketFunHandler) {
    const [state, setState] = useState({
        columns: [],
        rows: []
    })

    useEffect(()=>{
        setState(prev=>{            
            if (prev.columns.length) {
                prev.columns.forEach((column) => {
                    if (column.type === "strategy") {
                        column.isHide = !strategyList.some(strategy => strategy.selected && strategy.stg.code === column.label.key)
                    }
                })

                return {...prev}
            }

            return prev
        })
    }, [strategyList])

    useEffect(() => {
        state.columns.forEach((column) => {
            if (column.type === "strategy") {
                column.isHide = !strategyList.some(strategy => strategy.selected && strategy.stg.code === column.label.key)
            }
        })

        setState(state)
    }, [state])

    useEffect(()=>{
        setState(prev=>{
            if (prev.rows.length) {
                const total = {
                    pAndl: 0,
                    marginVal: 0,
                    // list of strategy
                }

                prev.columns.forEach(col => {
                    if (col.type === "strategy") {
                        total[col.id] = 0
                    }
                })

                prev.rows.forEach((row) => {
                    if (!row.type) {
                        row.isHide = !userList.some(user => user.selected && user.userId === row.userid)
                    }

                    if (!row.isHide && row.type !== "total") {
                        calcTotal(row, total)
                    }
                })

                // Fixed the number till 2 digits after decimal
                for (let key in total) {
                    if ('number' === typeof total[key]) {
                        total[key] = total[key].toFixed(2)
                    }
                }

                // Update the total row in rows. this should be at the end
                Object.assign(prev.rows[prev.rows.length - 1], total)

                return {...prev}
            }

            return prev
        })
    },[userList])

    useEffect(() => {
        // Order Configurations
        root.apis.orderConfig.get()
            .then(d => {
                console.log("order_config_data rest_api")
                setState((prev) => {
                    const stgCols = d.data.stgCols
                    const stgConfigs = d.data.stgConfigs || []
                    const total = {
                        pAndl: 0,
                        marginVal: 0,
                        // list of strategy
                    }

                    const createTableRows = (cfgs={}) => {
                        const strategyList = {
                            strategyList: []
                        }
                        const strategyListStatus = {};

                        (cfgs.stgData || []).forEach((dta={}) => {
                            strategyList["strategyList"].push(dta)
                            strategyList[dta.stgId] = dta.lots
                            strategyListStatus["status"] = dta.status
                            strategyListStatus["status_"+dta.stgId] = dta.status
                        })
                        
                        const data = {
                            id: cfgs.userId,
                            editable: Boolean(cfgs.isAccEnable),
                            userid: cfgs.userId,
                            fullName: cfgs.fullName,
                            pAndl: cfgs.pnl,
                            isPAndLUp: Boolean(cfgs.isPnLUp),
                            marginVal: cfgs.mtm,
                            ...strategyList,
                            ...strategyListStatus
                        }

                        calcTotal(data, total)

                        return data
                    }

                    const handleOnEditSwitchChange = (e, row) => {
                        if(row.isEditRequestInProgress)return;
                        
                        row.isEditRequestInProgress = true
                        var v = e.target.checked
                        root.apis.orderConfig.enableDisableClientStrategies(row.userid, v, false, false, "oc-cl-disabled")
                        .then(d=>{
                            console.log("updated = ",d )

                            if (d.status === 201) {
                                mToast.success(d.data.msg)
                            }
                            else {
                                mToast.error("Couldn't complete request. Something went wrong!")
                            }

                            row.isEditRequestInProgress = false
                            row.editable = v
                            
                            setState(prev => ({ ...prev }))
                        })
                        .catch(e=>{
                            row.isEditRequestInProgress = false
                            mToast.error(e.message)
                        })
                    }

                    const handleCashOut = (stg, row={}) => {
                        console.log("row = ", stg, row)
                        root.apis.orderConfig.cashOut(stg.id, stg.name, "oc-current-day-stop")
                        .then(d=>{
                            console.log('d = ', d)
                            
                            if (d.status === 201) {
                                mToast.success(d.data.msg)
                            }
                            else {
                                mToast.error("Couldn't complete request. Something went wrong!")
                            }
                        })
                        .catch(e=>{
                            mToast.error(e.message)
                        })
                    }

                    const newState = {
                        ...prev,
                        columns: [
                            {
                                id: "editable",
                                label: "",
                                format: (value, row={}) => (
                                    row.type === "total" ? null :
                                        <Switch
                                            size="small"
                                            checked={value}
                                            onChange={e=>handleOnEditSwitchChange(e, row)}
                                        />
                                ),
                                cellAttr: { style: { minWidth: 50, left: 0 }, align: "left", className: "fixed-left" }
                            },
                            {
                                id: "preview",
                                label: "",
                                format: (value, row={}) => (
                                    row.type === "total" ? null : <Preview data={row} />
                                ),
                                cellAttr: { style: { minWidth: 50, left: 0, cursor: "pointer" }, align: "center" }
                            },
                            { id: "userid", label: "UserId", fixed: "left", cellAttr: { style: { minWidth: 130, left: 50 }, align: "center", className: "fixed-left" }, format: (value, row={}) => {
                                return !row.type ? row.userid + " (" + row.fullName + ")" : ""
                            } },
                            { id: "pAndl", label: "P&L", fixed: "left", align: "center", cellAttr: { style: { minWidth: 110, left: 160 }, align: "center", className: "fixed-left" }, format: (value, row={}) => {
                                return parseFloat(value).toFixed(2)
                            } },
                            { id: "marginVal", label: "Margin Val.", fixed: "left", cellAttr: { style: { minWidth: 110, left: 269 }, align: "center", className: "fixed-left" }, format: (value, row={}) => {
                                return parseFloat(value).toFixed(2)
                            } },
                            ...stgCols.map((stg) => {
                                if (!(stg.id in total)) total[stg.id] = 0
                                return {
                                    id: stg.id,
                                    label: formatStgLabel(stg.code, stg.stgEnable),
                                    type: "strategy",
                                    cellAttr: {
                                        style: { "minWidth": 103, "align": "center" },
                                        align: "center"
                                    },
                                    format(value, row={}, _, setOrderMgmEditDialog) {
                                        if (typeof value !== "undefined") { // Added if condition so that the edit menu shouldn't render in case the value is undefined
                                            return (
                                                <Box
                                                    display={
                                                        row.type === "total"
                                                            ? "block"
                                                            : "flex"
                                                    }
                                                    gap={1}
                                                    justifyContent="center"
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        textAlign="center"
                                                    >
                                                        <Typography width="100%">
                                                            {value}
                                                        </Typography>
                                                    </Box>
                                                    {value && row.type !== "total" ? (
                                                        <Box
                                                            mt={1}
                                                            display="flex"
                                                            alignItems="center"
                                                        >
                                                            <Box>
                                                                <Box
                                                                    sx={{
                                                                        width: 10,
                                                                        aspectRatio: "1/1",
                                                                        borderRadius: "100%",
                                                                        bgcolor:
                                                                            row.status
                                                                                ? OrderStatusList[
                                                                                        row[
                                                                                            "status_" +
                                                                                                stg.id
                                                                                        ]
                                                                                  ]
                                                                                        ?.color[800]
                                                                                : "transparent", //(Math.random() > .5 ? green : red)[800]
                                                                    }}
                                                                ></Box>
                                                            </Box>
                                                        </Box>
                                                    ) : null}
                                                    {(row.type !== "total") && (row.editable) && (
                                                        <Edit
                                                            className="edit-strategy"
                                                            style={{
                                                                transform:
                                                                    "scale(.6)",
                                                            }}
                                                            onClick={() => {
                                                                setOrderMgmEditDialog((prev) => ({
                                                                        ...prev,
                                                                        open: true,
                                                                        rowData: row || [],
                                                                        strategyId: stg.id,
                                                                        strategyData: (row.strategyList || []).find(s => s.stgId === stg.id)
                                                                    })
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                    {row.type === "total" ? (
                                                        <Button
                                                            size="sm"
                                                            style={{
                                                                fontSize: 12,
                                                                padding: 0,
                                                                textTransform:
                                                                    "initial",
                                                            }}
                                                            onClick={() =>
                                                                handleCashOut(
                                                                    stg,
                                                                    row
                                                                )
                                                            }
                                                        >
                                                            Current Day Stop
                                                        </Button>
                                                    ) : null}
                                                </Box>
                                            );
                                        }
                                    }
                                }
                            }),
                            // {
                            //     id: "edit",
                            //     label: "",
                            //     format(_, row = {}, c, setOrderMgmEditDialog) {
                            //         return (
                            //             row.type === "total" ? null :
                            //                 <Button
                            //                     startIcon={<Edit style={{ transform: 'scale(.8)' }} />}
                            //                     style={{ textTransform: "initial" }}
                            //                     variant="text"
                            //                     disabled={!row.editable}
                            //                     onClick={() => {
                            //                         console.log("row_clicked", row)
                            //                         setOrderMgmEditDialog(prev=>({
                            //                             ...prev,
                            //                             open: true,
                            //                             rowData: row || []
                            //                         }))
                            //                     }}
                            //                 >
                            //                     Edit
                            //                 </Button>
                            //         )
                            //     },
                            //     cellAttr: {
                            //         style: {
                            //             minWidth: 100,
                            //         },
                            //         align: "right",
                            //         className: "fixed-right"
                            //     }
                            // }
                        ],
                        rows: stgConfigs.map(createTableRows)
                    }

                    // fixed the number till 2 digits after decimal
                    for (let key in total) {
                        if ('number' === typeof total[key]) {
                            total[key] = total[key].toFixed(2)
                        }
                    }

                    // add the total row in rows. this should be at the end
                    newState.rows.push(Object.assign(total, {
                        type: "total",
                        userid: "Total",
                    }))

                    return newState
                })
            })
            .catch(e => {
                mToast.error(e.statusText)
            })

    }, [])

    function updatePnl(data, stateKey, dataKey) {
        data.forEach(elem => {
            state.rows.filter(row => row.userid === elem.userId).forEach(obj => {
                obj[stateKey] = elem[dataKey]
            })
        })

        const rowLen = state.rows.length - 1
        var total = 0

        if (rowLen !== -1) {
            for (let i=0; i < rowLen;i++) {
                total += Number(state.rows[i][stateKey])
            }

            state.rows[rowLen][stateKey] = total
        }

        setState(prev => ({...prev}))
    }

    // Update P&L
    socketFunHandler.current.mtmData = (data) => {updatePnl(data, "pAndl", "mtm")}

    // Update margin value
    socketFunHandler.current.mgrData = (data) => {updatePnl(data, "marginVal", "margin_val")}

    // Update strategy status
    socketFunHandler.current.stgStatus = (data) => {
        data.forEach(elem => {
            state.rows.filter(row => row.userid === elem.userId).forEach(obj => {
                obj[elem.id] = elem.lots
                obj["status_"+elem.id] = elem.status
            })
        })
    }

    return state
}

function ConfigurationList({ userList=[], strategyList=[], socketFunHandler }) {
    const [orderMgmEditDialog, setOrderMgmEditDialog] = useState({
        open: false,
        rowData: [],
        strategyData: {},
        strategyId: undefined
    })
    const state = useGetData(userList, strategyList, socketFunHandler)

    return (
        <Box mt={2}>

            <Paper
                className="table-freeze-row-column"
                sx={{ width: '100%', overflow: 'hidden', bgcolor: 'transparent' }}
            >
                <TableContainer
                    sx={{
                        height: `calc(100vh - ${topNavBarHeight + 90}px)`,
                        // maxWidth: '155vh',
                        width: '100%',
                        "& table tbody tr:last-child td": {
                            p: 2
                        }
                    }}
                    className='scrollbar'
                >
                    <MuiTable stickyHeader>
                        <TableHead>
                            <TableRow>
                                {state.columns.map((column) => {
                                    if(column.isHide) return null;

                                    const cellAttr = column.cellAttr || {}
                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            {...cellAttr}
                                        >
                                            {column.label}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                "& .MuiTableCell-root .MuiSvgIcon-root.edit-strategy": {
                                    display: "none",
                                    cursor: "pointer"
                                },
                                "& .MuiTableCell-root:hover":{
                                    "& .MuiSvgIcon-root.edit-strategy": {
                                        display: "block",
                                    },
                                }
                            }}
                        >
                            {state.rows.map((row, i) => {
                                const isTotalRow = row.type === "total"
                                
                                if (row.isHide) return null;

                                return (
                                    <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={i}
                                    >
                                        {state.columns.map((column) => {
                                            if (column.isHide) return null;
                                            const cellAttr = column.cellAttr || {}

                                            if (isTotalRow) {
                                                cellAttr.className = `${cellAttr.className} fixed-bottom`
                                            }

                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    {...cellAttr}
                                                >
                                                    {column.format
                                                        ? column.format(row[column.id], row, column, setOrderMgmEditDialog)
                                                        : row[column.id]
                                                    }
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </MuiTable>
                </TableContainer>

            </Paper>

            {/* <OrderManagementEditDialog
                open={orderMgmEditDialog.open}
                rowData={orderMgmEditDialog.rowData}
                setOpen={(open)=>setOrderMgmEditDialog({ ...orderMgmEditDialog, open })}
            /> */}
            <StrategyModal
                orderState={state}
                data={orderMgmEditDialog}
                setOpen={(open)=>setOrderMgmEditDialog({ ...orderMgmEditDialog, open })}
            />
        </Box>
    )
}


export function Configuration({ socketFunHandler }) {
    const [strategyList, setStrategyList] = useState([])
    const [userList, setUserList] = useState([])

    useState(() => {
        root.apis.strategy.get(null, {filtEnabled: true})
            .then(d => {
                const selOrderCfgStgVal = getCookie(constant.Cookies.SelOrderCfgStg)
                
                if (selOrderCfgStgVal) {
                    const selCfgIds = selOrderCfgStgVal.split(",").map((cfgId) => parseInt(cfgId))
                    
                    setStrategyList((d.data.strategy || []).map((stg) => {
                        var isSelected = false

                        if (selCfgIds.includes(stg.stgId)) {
                            isSelected = true
                        }

                        return { value: stg.name, selected: isSelected, stg: stg }
                    }))
                }
                else {
                    setStrategyList((d.data.strategy || []).map((stg) => ({ value: stg.name, selected: true, stg: stg })))
                }

            })
            .catch(e => {
                mToast.error(e.message)
            })

        root.apis.users.get()
            .then(d => {
                setUserList((d.data.users || []).map((user) => ({ value: user.userInfo.fullName, selected: true, userId: user.id })))
            })
            .catch(e => {
                mToast.error(e.message)
            })

    }, [])

    return (
        <Box>
            <SectionHeading
                heading="Order Management"
            >
                <Box display="flex" alignItems="center">
                    {/* Reload Button */}
                    <Button 
                        sx={{ minWidth: 20, p: 0, mr: 3 }} 
                        color="primary" 
                        onClick={() => {
                            window.location.reload();
                        }} 
                    > <CachedIcon sx={{ fontSize: 30 }} /> </Button>

                    <Box
                        sx={{ minWidth: 20, p: 0, mr: 3 }} 
                    >
                        <DropDown
                            multiselect
                            withSelectAllControl
                            defaultValue="Filter Client"
                            // selectAllText="Hide All"
                            // unSelectAllText="Show All"
                            value={userList.filter(obj=>!obj.selected)}
                            options={userList}
                            onSelect={(user, isSelected, isArray)=> {
                                if (isArray) {
                                    user.forEach(obj=>{
                                        obj.selected = isSelected
                                    })
                                } else {
                                    user.selected = isSelected
                                }
                                setUserList([...userList])
                            }}
                        />
                    </Box>

                    <DropDown
                        multiselect
                        withSelectAllControl
                        defaultValue="Filter Strategy"
                        // selectAllText="Hide All"
                        // unSelectAllText="Show All"
                        value={strategyList.filter(obj=>!obj.selected)}
                        options={strategyList}
                        onSelect={(strategy, isSelected, isArray)=> {
                            if(isArray){
                                strategy.forEach(obj=>{
                                    obj.selected = isSelected
                                })
                            }else{
                                strategy.selected = isSelected
                            }

                            const selStgList = strategyList.map(
                                (stg) => stg.selected ? stg.stg.stgId : null
                            ).filter(cfgId => cfgId !== null);
                            setCookie(constant.Cookies.SelOrderCfgStg, selStgList.join(","))

                            setStrategyList([...strategyList])
                        }}
                    />
                </Box>
            </SectionHeading>

            <ConfigurationList 
                userList={userList}
                strategyList={strategyList}
                socketFunHandler={socketFunHandler}
            />
        </Box>
    )
}