import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { SectionHeading } from "helpers/SectionHeading";
import CancelIcon from '@mui/icons-material/Cancel';
import CachedIcon from '@mui/icons-material/Cached';
import { useUrlParams } from "utils/useUrlParams";
import BlockIcon from '@mui/icons-material/Block';
import Typography from "@mui/material/Typography";
import { ServerStatus } from './ServerStatus';
import Edit from "@mui/icons-material/Edit";
import { Socket } from "Root/Common/Socket";
import { useEffect, useState } from "react";
import { mToast } from "Root/Common/Toast";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { Divider } from '@mui/material';
import { Link } from "react-router-dom";
import { Table } from "helpers/Table";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from "@mui/material/Box"
import { root } from "Root/root";

const scriptColumns = [
    { id: 'name', label: 'Name', minWidth: 170, align: 'left' },
    { id: 'dir', label: 'Directory', minWidth: 170, align: 'center' },
    { id: 'file', label: 'File', minWidth: 170, align: 'center' },
    { id: "psId", label: "Process", minWidth: 100, align: "center", format(value) {
        return <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                verticalAlign: "center"
            }}
        >
            <Box>
                {
                    value ? 
                    <CheckCircleIcon 
                        sx={{ color: "green" }}
                        fontSize="small"
                    /> :
                    <CancelIcon 
                        sx={{ color: "red" }}
                        fontSize="small"
                    />
                }
            </Box>
            <Box ml={1}>
                {
                    value ? `( ${value} )` : ""
                }
            </Box>
        </Box>
    } },
    { id: 'scpAction', label: "Action", minWidth: 10, align: 'right' }
];

const queueColumns = [
    { id: 'name', label: 'Name', minWidth: 170, align: 'left', format(value, row) {
        return `(${row.psCount}) ${value}`
    } },
    // { id: 'file', label: 'File', minWidth: 170, align: 'center' },
    // { id: 'psCount', label: 'Process', minWidth: 170, align: 'center' },
    { id: "psId", label: "Status", minWidth: 170, align: "center", format(value) {
        return <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                verticalAlign: "center"
            }}
        >
            <Box>
                {
                    value.length ? 
                    <CheckCircleIcon 
                        sx={{ color: "green" }}
                        fontSize="small"
                    /> :
                    <CancelIcon 
                        sx={{ color: "red" }}
                        fontSize="small"
                    />
                }
            </Box>
            <Box ml={1}>
                {
                    `( ${value.length} )`
                }
            </Box>
        </Box>
    } },
    // { id: 'qUtil', label: 'Queue Util.', minWidth: 170, align: 'center'},
    { id: 'cpu', label: 'CPU', minWidth: 170, align: 'center', format(value) {
        return `${value} %`
    } },
    { id: 'mem', label: 'Memory', minWidth: 170, align: 'center', format(value) {
        return `${value} GB`
    } },
    { id: 'queueAction', label: "Action", minWidth: 10, align: 'right' }
];

export function MonitorHome() {
    const [scripts, setScripts] = useState([])
    const [queues, setQueues] = useState([])
    const [serverStat, setServerStat] = useState({})
    const [activeTab, setActiveTab] = useState(false);
    const [isScriptsHidden, setIsScriptsHidden] = useState(true)
    const [isQueuesHidden, setIsQueuesHidden] = useState(false)

    const commonSel = useSelector(selector => selector.common)
    const loginId = commonSel.loginId

    const startStopScript = (scp, isStart) => {
        const payload = {
            "isStart": isStart,
            "dirPath": scp.dir,
            "fileName": scp.file,
            "psId": scp.ps_id
        }

        root.apis.monitor.startStopScript(payload)
        .then((data) => {
            if (data.data.msg) {
                mToast.success(data.data.msg)
            }

            window.location.reload();
        })
        .catch(err => {
            if (err.response) {
                err = err.response.data.err
            }
            else {
                err = err.statusText
            }

            mToast.error(err)
        })
    }

    const startStopQueue = (queue, isStart) => {
        const payload = {
            "isStart": isStart,
            "dirPath": queue.dir,
            "fileName": queue.file,
            "psId": queue.ps_id
        }

        root.apis.monitor.startStopQueue(payload)
        .then((data) => {
            if (data.data.msg) {
                mToast.success(data.data.msg)
            }

            window.location.reload();
        })
        .catch(err => {
            if (err.response) {
                err = err.response.data.err
            }
            else {
                err = err.statusText
            }

            mToast.error(err)
        })
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue)

        if (newValue === "1") {
            if (isScriptsHidden !== true) {
                setIsScriptsHidden(!isScriptsHidden)
            }
            setIsQueuesHidden(false)
        }
        
        if (newValue === "2") {
            setIsQueuesHidden(!isQueuesHidden)
            setIsScriptsHidden(false)
        }
    }

    const monitorPingPong = async (socket) => {        
        while (socket.socket.readyState === WebSocket.OPEN) {
            console.log("Sending monitorPingPong request")

            socket.send(JSON.stringify({"connect": true}))
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }

    const monitorSocket = () => {
        const connData = {"loginId": loginId}
        const connQuery = new URLSearchParams(connData);

        var socket = new Socket(root.constant.WEBSOCKET_URL + "broadcast/monitor/?" + connQuery);
        
        socket.onOpen(async function () {
            console.log("Connecting to monitor websocket")

            socket.send(JSON.stringify({"connect": true}))
            await monitorPingPong(socket);
        })
        
        socket.onMessage(function (data) {
            const mData = data.mData

            if (mData) {
                setScripts(prev => {
                    mData.scripts.forEach(elem => {
                        const scpData = prev.find(e => e.file === elem.file)
                        
                        if (scpData) {
                            scpData.psId = elem.ps_id
                        }
                    })
    
                    return [...prev]
                })

                setQueues(prev => {
                    mData.queues.forEach(elem => {
                        const queData = prev.find(e => e.file === elem.file)
                        
                        if (queData) {
                            queData.psId = elem.ps_id
                            queData.qUtil = elem.q_util
                            queData.cpu = elem.cpu
                            queData.mem = elem.mem
                        }
                    })
    
                    return [...prev]
                })

                setServerStat(mData.server)
            }
        })

        var isMSocketClose = false;
    
        socket.onClose(function() {
            console.log("Monitor websocket connection closed")

            // Attempt to reconnect to the websocket after a delay
            if (!isMSocketClose) {
                setTimeout(() => {
                    console.log(`Reconnecting to monitor websocket ...`);
    
                    monitorSocket()
                }, 1000);
            }
        })

        return () => {
            isMSocketClose = true
            socket.close()
        }
    }

    useEffect(() => {
        return monitorSocket()
    }, [])

    useEffect(() => {
        const createScriptData = (scp = {}) => {
            return {
                name: scp.name,
                dir: scp.dir,
                file: scp.file,
                psId: scp.ps_id,
                scpAction: (
                    <Box display="flex" justifyContent="end">
                        <Box>
                            <Button
                                startIcon={<PlayArrowIcon style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                                disabled={scp.ps_id ? true : false}
                                sx={{
                                    color: "#1976D2 !important",
                                    opacity: scp.ps_id ? 0.5 : 1
                                }}
                                onClick={() => {
                                    startStopScript(scp, true)
                                }}
                            >
                                Start Script
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                startIcon={<BlockIcon style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                                color="error"
                                disabled={scp.ps_id ? false : true}
                                sx={{
                                    color: "#CA382E !important",
                                    opacity: !scp.ps_id ? 0.5 : 1
                                }}
                                onClick={() => {
                                    startStopScript(scp, false)
                                }}
                            >
                                Stop Script
                            </Button>
                        </Box>
                    </Box>
                )
            };
        }

        const createQueueData = (queue = {}) => {
            return {
                name: queue.name,
                file: queue.file,
                psCount: queue.ps_count,
                psId: queue.ps_id,
                qUtil: queue.q_util,
                cpu: queue.cpu,
                mem: queue.mem,
                queueAction: (
                    <Box display="flex" justifyContent="end">
                        <Box>
                            <Button
                                startIcon={<PlayArrowIcon style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                                disabled={queue.ps_id.length}
                                sx={{
                                    color: "#1976D2 !important",
                                    opacity: queue.ps_id.length ? 0.5 : 1
                                }}
                                onClick={() => {
                                    startStopQueue(queue, true)
                                }}
                            >
                                Start Queue
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                startIcon={<BlockIcon style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                                color="error"
                                disabled={!queue.ps_id.length}
                                sx={{
                                    color: "#CA382E !important",
                                    opacity: !queue.ps_id.length ? 0.5 : 1
                                }}
                                onClick={() => {
                                    startStopQueue(queue, false)
                                }}
                            >
                                Stop Queue
                            </Button>
                        </Box>
                    </Box>
                )
            };
        }

        root.apis.monitor.getScripts()
        .then(data => {
            setScripts((data.data.scripts || []).map(createScriptData))
            setQueues((data.data.queues || []).map(createQueueData))

            setActiveTab("1")
        })
        .catch(err => {
            const e_resp = err.response

            if (e_resp.status === 401) {
                mToast.error(e_resp.data.detail)
            }
            else {
                mToast.error("getScripts error = ")
            }
        })
    }, [])

    const renderTable = (colsData, rowsData) => {
        return (
            <Box MT={2}>
                <Table
                    columns={colsData}
                    rows={rowsData}
                />
            </Box>
        )
    }

    return (
        <Box>
            <Box>
                <SectionHeading
                    heading={
						<Box
							sx={{
								display: "flex",
								gap: 1,
								"& .MuiTypography-root": { fontSize: 18 },
							}}
						>
                            <Typography>Server Status</Typography>
                            <Button 
                                sx={{ minWidth: 20, p: 0, pb: 0.5 }} 
                                color="primary" 
                                onClick={() => {
                                    window.location.reload();
                                }} 
                            > <CachedIcon sx={{ fontSize: 30 }} /> </Button>

						</Box>
					}
                />
                <ServerStatus 
                    serverStat={serverStat}
                />
            </Box>

            <Box mt={4}>
                <Box sx={{ width: activeTab ? '90%' : '100%' }}>
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                    >
                        <Tab value="1" label={"Scripts"} style={{ color: "White" }} />
                        <Tab value="2" label={"Queues"} style={{ color: "White" }} />
                    </Tabs>
                </Box>

                <Divider color="white" />

                {/* <Box mt={2}>
                    <Table
                        columns={scriptColumns}
                        rows={scripts}
                    />
                </Box> */}

                {
                    isScriptsHidden ? renderTable(scriptColumns, scripts) : null
                }
                {
                    isQueuesHidden ? renderTable(queueColumns, queues) : null
                }
            </Box>
        </Box>
    )
}