import { useUrlParams } from "../../../utils/useUrlParams"
import { MonitorHome } from "./MonitorHome";
import Box from "@mui/material/Box"

export function Monitor() {
    document.title = "Monitor Server";

    return (
        <Box>
            <MonitorHome />
        </Box>
    )
}