import { Box, Button, IconButton } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Typography, DialogActions } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DownloadIcon from '@mui/icons-material/Download';
import { useMemo, forwardRef, useState, useRef } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Close from "@mui/icons-material/Close";
import { mToast } from "Root/Common/Toast";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { root } from "Root/root";

export function CreateInstanceDialog({
    open = false, onClose = () => undefined, setIsInstanceModalOpen = () => undefined, commonSel = {}
}) {
    const Transition = useMemo(() => forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    }), [])

    const [fileObj, setFileObj] = useState(null);
    const fileInputRef = useRef(null);
    
    const handleOnFileChange = (event) => {
        if (event.target.files.length > 0) {
            setFileObj(event.target.files[0]);
        }
    };

    const handleOnSaveInstance = () => {
        const payload = new FormData();
        payload.append("login_id", commonSel.loginId);
        payload.append("inst_xlsx", fileObj);

        root.apis.strategyInstance.saveInstances(payload)
        .then((resp) => {
            if (resp.status === 201) {
                mToast.success(resp.data.msg)

                setFileObj(null)
                setIsInstanceModalOpen(false)

                fileInputRef.current.value = ''
                window.location.reload();
            }
            else {
                mToast.error(resp.data.detail)
            }
        })
        .catch((err) => {
            const err_resp = err.response

            if (err_resp) {
                if (err_resp.status === 403) {
                    mToast.error(err_resp.data.detail)
                }
                else {
                    mToast.error("Couldn't complete request. Something went wrong!")
                }
            }
            else {
                mToast.error(err.message)
            }
        })
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => { }}
            scroll="paper"
            maxWidth="md"
            PaperProps={{
                sx: {
                    minHeight: "40%",
                    minWidth: "40%",
                    color: "#000",
                    "& table": {
                        "thead tr th": {
                            p: 2
                        },
                        "tbody tr td": {
                            color: "#000",
                            py: 2
                        }
                    },
                    "& button": {
                        textTransform: "initial"
                    },
                    "& .mk-flex": {
                        mt: 3,
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 2,
                        "input": {
                            color: "black",
                        },
                    }
                }
            }}
        >
            <DialogTitle>
                <Box display="flex" alignContent="center" justifyContent="space-between">
                    <Box>Create Strategy Instance</Box>
                    <Box display="flex" alignContent="center">
                        <Button 
                            variant="contained" 
                            color="success" 
                            startIcon={<DownloadIcon />}
                            onClick={() => {
                                root.apis.strategyInstance.getExcel()
                            }}
                        >
                            Download Excel
                        </Button>
                        <Box ml={2}><IconButton onClick={onClose}><Close /></IconButton></Box>
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent>
                <Box display="flex">
					<Box color="red" mr={1.5}>*</Box> 
					<Box display="flex">
						<Typography fontWeight="bold">Select the excel file that contains your instance configurations:</Typography>
					</Box>
				</Box>

                <Box mt={2}>
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Select Excel File
                        <input
                            type="file"
                            hidden
                            onChange={handleOnFileChange}
                            ref={fileInputRef}
                        />
                    </Button>

                    {fileObj && (
                        <Typography display="flex" alignItems="center" variant="body2" mt={2}>
                            <Typography fontWeight="bold" mr={1}>Selected:</Typography> {fileObj.name}
                        </Typography>
                    )}
                </Box>
            </DialogContent>

            <DialogActions>
                <Box
                    sx={{
                        width: "100%",
                        px: 2
                    }}
                >
                    <Box className="mk-flex" style={{ justifyContent: "end" }}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <Button 
                                onClick={handleOnSaveInstance} 
                                variant="contained" 
                                startIcon={<FileUploadIcon />}
                            >
                                Save Instance
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
}