// store all constant data that can be used throughout the application
export const constant = {

    REST_API_VERSION: process.env.REACT_APP_REST_API_VERSION,
    SERVER_URL: process.env.REACT_APP_SERVER_URL,
    WEBSOCKET_URL: process.env.REACT_APP_WEBSOCKET_URL,

    ApplicationIcon: {
        MAIN: "./assets/img/header_logo.png"
    },

    Cookies: {
        LoginId: "_loginID",
        AccessToken: "_accessToken",
        SelOrderCfgStg: "_selOrderCfgStg"
    },

    Market: {
        MStartTime: "09:00:00",
        MEndTime: "15:30:00"
    },

    Authorization: {
        DefaultReRoutePath: "/positions",
        NonAdminRoutes: [
            "/positions", "/marketWatch", "/archive"
        ]
    }

}   