import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { SectionHeading } from "helpers/SectionHeading";
import CancelIcon from '@mui/icons-material/Cancel';
import CachedIcon from '@mui/icons-material/Cached';
import { useUrlParams } from "utils/useUrlParams";
import BlockIcon from '@mui/icons-material/Block';
import SSConfirmModal from './SSConfirmModal';
import Edit from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { mToast } from 'Root/Common/Toast';
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Table } from "helpers/Table";
import Box from "@mui/material/Box";
import { root } from "Root/root";

const columns = [
    { id: "isEnable", label: "", minWidth: 25, align: "center", format(value) { return <Box>
        <Box
            sx={{
                width: 10,
                aspectRatio: "1/1",
                borderRadius: "100%",
                bgcolor: value ? "green" : "red"
            }}
        ></Box>
    </Box> } },
    { id: "stgId", label: "ID", minWidth: 10, align: "left"},
    { id: "stgCode", label: "Code", minWidth: 100, align: "center" },
    { id: "stgName", label: "Name", minWidth: 100, align: "center" },
    // { id: "stgDesc", label: "Description", minWidth: 170, align: "center" },
    { id: "maxLots", label: "Max Lots", minWidth: 100, align: "center" },
    { id: "psId", label: "Process", minWidth: 100, align: "center", format(value) {
        return <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                verticalAlign: "center"
            }}
        >
            <Box>
                {
                    value ? 
                    <CheckCircleIcon 
                        sx={{ color: "green" }}
                        fontSize="small"
                    /> :
                    <CancelIcon 
                        sx={{ color: "red" }}
                        fontSize="small"
                    />
                }
            </Box>
            <Box ml={1}>
                {
                    value ? `( ${value} )` : ""
                }
            </Box>
        </Box>
    } },
    { id: "startStopUser", label: "Ownership", minWidth: 100, align: "center", format(value, row={}) {
        return value ? `( ${value} ) ${row.userName}` : ""
    } },
    { id: 'stgAction', label: "Action", minWidth: 10, align: 'right' }
];

export function StrategyHome() {
    
    const [, updateParams] = useUrlParams();
    const [strategies, setStrategies] = useState({
        list: []
    })

    const [isSsConfirmModalOpen, setIsSsConfirmModalOpen] = useState(false)
    const [sscmStgId, setSscmStgId] = useState()
    const [sscmStgName, setSscmStgName] = useState()
    const [isPsStart, setIsPsStart] = useState(false)

    useEffect(() => {
        const createData = (stg = {}) => {
            return {
                isEnable: stg.isEnable,
                isEditable: stg.isEditable,
                stgId: stg.stgId,
                psId: stg.psId,
                stgCode: stg.code,
                stgName: stg.name,
                // stgDesc: stg.desc,
                maxLots: stg.maxLots,
                startStopUser: stg.startStopUser,
                userName: stg.userName,
                stgAction: (
                    <Box display="flex" justifyContent="end">
                        <Link to={"/strategyManagement?type=assign&stgid=" + stg.stgId}>
                            <Button
                                startIcon={<AssignmentReturnIcon style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                            >
                                Assign
                            </Button>
                        </Link>
                        <Link to={"/strategyManagement?type=edit&stgid=" + stg.stgId}>
                            <Button
                                startIcon={<Edit style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                            >
                                Edit
                            </Button>
                        </Link>
                        <Box>
                            <Button
                                startIcon={<PlayArrowIcon style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                                disabled={!stg.isEditable || stg.psId}
                                sx={{
                                    color: "#1976D2 !important",
                                    opacity: stg.isEditable ? stg.psId ? 0.5 : 1 : 0.5
                                }}
                                onClick={() => {
                                    setIsSsConfirmModalOpen(true)
                                    setSscmStgId(stg.stgId)
                                    setSscmStgName(stg.name)
                                    setIsPsStart(true)
                                }}
                            >
                                Start Process
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                startIcon={<BlockIcon style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                                color="error"
                                disabled={!stg.isEditable || !stg.psId}
                                sx={{
                                    color: "#CA382E !important",
                                    opacity: stg.isEditable ? !stg.psId ? 0.5 : 1 : 0.5
                                }}
                                onClick={() => {
                                    setIsSsConfirmModalOpen(true)
                                    setSscmStgId(stg.stgId)
                                    setSscmStgName(stg.name)
                                    setIsPsStart(false)
                                }}
                            >
                                Stop Process
                            </Button>
                        </Box>
                    </Box>
                )
            };
        }

        root.apis.strategy.get()
            .then(d => {
                const list = (d.data.strategy || []).map(createData)

                setStrategies(prev => ({
                    ...prev,
                    list,
                }))
            })
            .catch(e => {
                console.log("Get Strategies Error: ", e)
                mToast.error(e.statusText)
            })
    }, [])

    return (
        <Box>
            <SectionHeading
                heading={"Strategy Management"}
            >
                <Box>
                    <Button 
                        sx={{ minWidth: 20, p: 0, mr: 3 }} 
                        color="primary" 
                        onClick={() => {
                            root.apis.strategy.checkStgProcess()
                            .then((data) => {
                                console.log("checkStgProcess resp:", data.data.msg)
                            })
                            .catch((err) => {
                                console.log("checkStgProcess error:", err)
                                mToast.error(err.statusText)
                            })

                            window.location.reload();
                        }} 
                    > <CachedIcon sx={{ fontSize: 30 }} /> </Button>

                    <Button
                        variant="contained"
                        style={{ textTransform: 'initial' }}
                        onClick={() => updateParams({ "type": "create" })}
                    >
                        Add New Strategy
                    </Button>
                </Box>
            </SectionHeading>

            <Box mt={2}>
                <Table
                    columns={columns}
                    rows={strategies.list}
                />
            </Box>

            <SSConfirmModal 
                open={isSsConfirmModalOpen}
                handleClose={() => setIsSsConfirmModalOpen(false)}
                stgId={sscmStgId}
                stgName={sscmStgName}
                isPsStart={isPsStart}
            />
        </Box>
    )

}