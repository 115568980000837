import { mToast } from "Root/Common/Toast";
import moment from 'moment';

const StrategyInstance = (self) => ({

    getExcel() {
        const cTime = moment(Date.now()).format("HH_mm_ss");

        self.axios({
            url: self.getRestUrl(["strategy", "instance", "get_xlsx"]),
            method: "GET",
            responseType: "blob",
            headers: {
                "Accept": "application/json"
            }

        }).then((response) => {
            const href = URL.createObjectURL(response.data);
        
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", `instances_${cTime}.xlsx`);
            
            document.body.appendChild(link);
            link.click();
        
            document.body.removeChild(link);
            URL.revokeObjectURL(href);

        }).catch((error) => {
            console.log("getExcel Error:", error)

            const e_resp = error.response

            if (e_resp) {
                if (e_resp.status === 403) {
                    e_resp.data.text()
                    .then(data => {
                        data = JSON.parse(data)
    
                        mToast.error(data.err)
                    })
                    .catch("Couldn't download excel. Something went wrong!")
                }
                else {
                    mToast.error("Couldn't download excel. Something went wrong!")
                }
            }
            else {
                mToast.error(error.message)
            }
        });
    },
    getInstances(loginId) {
        return self.axios.get(self.getRestUrl(["strategy", "instance", loginId]))
    },
    saveInstances(payload={}) {
        return self.axios.post(self.getRestUrl(["strategy", "instance"]), payload)
    },
    deleteInstance(cfgId) {
        return self.axios.delete(self.getRestUrl(["strategy", "instance", "delete", cfgId]))
    },
    tradeBook(cfgId) {
        return self.axios.get(self.getRestUrl(["strategy", "instance", "trades", cfgId]))
    },
    squareOff(cfgId) {
        return self.axios.post(self.getRestUrl(["strategy", "instance", "square_off", cfgId]))
    },
    getInstMulp(cfgId) {
        return self.axios.get(self.getRestUrl(["strategy", "instance", "mulp", cfgId]))
    },
    saveInstMulp(payload={}) {
        return self.axios.post(self.getRestUrl(["strategy", "instance", "mulp"]), payload)
    }
})

export default StrategyInstance