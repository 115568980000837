import { Card, CardContent, Typography, Button } from "@mui/material";
import { SystemRestartDialog } from "./SystemRestartDialog";
import Box from "@mui/material/Box";
import { useState } from "react";

export function ServerStatus({ serverStat }) {
    const [isRestartSysModalOpen, setIsRestartSysModalOpen] = useState(false)

    return (
        <Box mt={2} display="flex" justifyContent="space-between" gap={5}>
            <Card
                sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    border: "4px solid #FFF",
                    borderRadius: "8px",   
                    overflow: "auto",
                    width: "100%"
                }}
            >
                <Box display="flex" alignContent="center" gap={5}>
                    <CardContent 
                        sx={{
                            minWidth: "150px"
                        }}
                    >
                        <Typography variant="body2">
                            ----- CPU -----
                        </Typography>
                        <Typography
                            variant="body2"
                            noWrap
                            sx={{ textOverflow: 'ellipsis' }}    
                        >
                            Total: {serverStat.cpu_t}
                        </Typography>
                        <Typography
                            variant="body2"
                            noWrap
                            sx={{ textOverflow: 'ellipsis' }}    
                        >
                            Threads: {serverStat.cpu_ut} ({serverStat.cpu_u_perc} %)
                        </Typography>
                    </CardContent>

                    <CardContent
                        sx={{
                            minWidth: "150px"
                        }}
                    >
                        <Typography variant="body2">
                            ----- Memory -----
                        </Typography>
                        <Typography
                            variant="body2"
                            noWrap
                            sx={{ textOverflow: 'ellipsis' }}    
                        >
                            Total: {serverStat.mem_t} GB
                        </Typography>
                        <Typography
                            variant="body2"
                            noWrap
                            sx={{ textOverflow: 'ellipsis' }}    
                        >
                            Used: {serverStat.mem_used} GB ({serverStat.mem_u_perc} %)
                        </Typography>
                        <Typography
                            variant="body2"
                            noWrap
                            sx={{ textOverflow: 'ellipsis' }}    
                        >
                            Available: {serverStat.mem_avail} GB
                        </Typography>
                    </CardContent>

                    <CardContent
                        sx={{
                            minWidth: "150px"
                        }}
                    >
                        <Typography variant="body2">
                            ----- Storage -----
                        </Typography>
                        <Typography
                            variant="body2"
                            noWrap
                            sx={{ textOverflow: 'ellipsis' }}    
                        >
                            Total: {serverStat.du_t} GB
                        </Typography>
                        <Typography
                            variant="body2"
                            noWrap
                            sx={{ textOverflow: 'ellipsis' }}    
                        >
                            Used: {serverStat.du_u} GB ({serverStat.du_u_perc} %)
                        </Typography>
                        <Typography
                            variant="body2"
                            noWrap
                            sx={{ textOverflow: 'ellipsis' }}    
                        >
                            Available: {serverStat.du_f} GB
                        </Typography>
                    </CardContent>

                    <CardContent
                        sx={{
                            minWidth: "150px"
                        }}
                    >
                        <Typography variant="body2">
                            ----- Network -----
                        </Typography>
                        <Typography
                            variant="body2"
                            noWrap
                            sx={{ textOverflow: 'ellipsis' }}    
                        >
                            Bytes In: {serverStat.nio_br} GB
                        </Typography>
                        <Typography
                            variant="body2"
                            noWrap
                            sx={{ textOverflow: 'ellipsis' }}    
                        >
                            Bytes Out: {serverStat.nio_bs} GB
                        </Typography>
                    </CardContent>
                </Box>
            </Card>

            <Card
                sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    border: "4px solid #FFF",
                    borderRadius: "8px",   
                }}
            >
                <CardContent 
                    sx={{
                        minWidth: "200px",
                        display: "flex",         // Use Flexbox
                        flexDirection: "column", // Align items in a column
                        height: "100%",
                    }}
                >
                    <Box>
                        <Button
                                variant="contained"
                                style={{ textTransform: "initial" }}
                                onClick={() => {
                                    if (serverStat.net_addr) {
                                        window.open(`http://${serverStat.net_addr}:15672`, "_blank");
                                    }
                                }}
                            >
                                RabbitMQ
                        </Button>
                    </Box>

                    <Box mt={2}>
                        <Button
                                variant="contained"
                                style={{ textTransform: "initial" }}
                                color="error"
                                onClick={() => {setIsRestartSysModalOpen(true)}}
                            >
                                Restart System
                        </Button>
                    </Box>
                </CardContent>
            </Card>

            <SystemRestartDialog
                open={isRestartSysModalOpen}
                onClose={() => setIsRestartSysModalOpen(false)}
                setIsRestartSysModalOpen={setIsRestartSysModalOpen}
            />
        </Box>
    )
}